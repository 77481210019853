<template>
  <div class="tooltip-container" @mouseover="isVisible = true" @mouseleave="isVisible = false"
       style="position: relative; display: inline-block;">
    <!-- Dynamic icon rendering based on type -->
    <img style="cursor: help" v-if="type === 'related_videos'" src="@/assets/icon_screen.svg" alt="Related Videos Icon"
         class="icon">
    <img style="cursor: pointer" v-if="type === 'foglia_bianca'" src="@/assets/icon_leaf_empty.svg"
         alt="Empty Leaf Icon" class="icon"
         @click="redirectToGreenProfessions">
    <img style="cursor: pointer" v-if="type === 'foglia_verde'" src="@/assets/icon_leaf_green.svg" alt="Green Leaf Icon"
         class="icon"
         @click="redirectToGreenProfessions">
    <img style="cursor: pointer" v-if="type === 'foglia_verde_w'" src="@/assets/icon_leaf_green_w.svg"
         alt="Green Leaf Icon"
         class="icon"
         @click="redirectToGreenProfessions">
    <img style="cursor: pointer" v-if="type === 'foglia_bianca_w'" src="@/assets/icon_leaf_empty_w.svg"
         alt="Empty Leaf Icon"
         class="icon"
         @click="redirectToGreenProfessions">

    <div v-if="isVisible" class="tooltip" :style="{display: isVisible ? 'block' : 'none'}">{{ tooltipContent }}</div>
  </div>
</template>


<script>
export default {
  name: 'InfoIcon',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    redirectToGreenProfessions() {
      // Redirect to the /professioni-verdi-e-rinverdite route
      this.$router.push('/professioni-verdi-e-rinverdite');
    }
  },
  computed: {
    tooltipContent() {
      switch (this.type) {
        case 'related_videos':
          return 'La professione è arricchita da video di approfondimento';
        case 'foglia_bianca':
          return 'Professione rinverdita perché la formazione include insegnamenti sulla sostenibilità ambientale';
        case 'foglia_verde':
          return 'Professione verde perché ha come obiettivo principale la sostenibilità ambientale';
        case 'foglia_bianca_w':
          return 'Professione rinverdita perché la formazione include insegnamenti sulla sostenibilità ambientale';
        case 'foglia_verde_w':
          return 'Professione verde perché ha come obiettivo principale la sostenibilità ambientale';
        default:
          return ''; // Default content or you can set some error message
      }
    }
  }
};
</script>


<style scoped>
@keyframes dance {
  0%, 100% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
}

.icon:hover {
  /* Apply the animation to the green leaf icon only */
  animation: dance 0.5s ease-in-out infinite;
}

/* Existing styles remain unchanged */
.icon {
  width: 20px;
  z-index: 1000;
}

.tooltip {
  position: absolute;
  bottom: 140%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f9f9f9;
  color: #333;
  padding: 10px 15px;
  border-radius: 16px;
  font-size: 14px;
  z-index: 1011;
  width: 180px;
  display: none;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-color: #f9f9f9;
  z-index: -1;
}

</style>



