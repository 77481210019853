<template>
  <div className="bookshelf">
    <BookComponent
        v-for="book in books"
        :key="book"
        :letter="book"
        @bookSelected="onBookSelected"
    />
  </div>
</template>

<script>
import BookComponent from './BookComponent.vue';

export default {
  name: 'BookShelfComponent',
  components: {
    BookComponent,
  },
  props: {
    books: Array,
  },
  methods: {
    onBookSelected(letter) {
      this.$emit('bookSelected', letter);
    },
  },
};
</script>

<style scoped>
.bookshelf {
  display: flex; /* Continue using flexbox to layout books in a row */
  justify-content: flex-start; /* Align books to the start of the container */
  align-items: center; /* Center books vertically */
  padding: 15px; /* Add some padding around the bookshelf */
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 75%;
  max-width: 1000px;
  margin-bottom: 50px;
  margin-left: 0px;
  margin-top: 15px;
}

.book {
  flex: 0 0 auto; /* Do not grow, do not shrink, and be automatically sized by content */
  transition: transform 0.3s ease; /* Smooth transition for any transformation */
  cursor: pointer; /* Change cursor to indicate clickable items */
  border-right: 1px dashed white; /* Add border-right to all books */

}

.book:last-child {
  border-right: none;
}


@media (max-width: 992px) {
  .bookshelf {
    width: 92%;
    margin-left: 0px;
    margin-bottom: 35px;
    padding: 15px;
    background-color: #D2D3D3;
  }
}

</style>

