<template>
  <div v-if="project">
    <Header :showSearchButton="true"/>
    <div class="sticky-bar" v-if="project" :class="{ visible: stickyVisible }">
      <div v-for="(section, index) in sectionTitles" :key="index" class="sticky-title"
           :class="{ active: index === activeSection }" @click="scrollToSection(index)">
        {{ section }}
      </div>
    </div>
    <img v-if="project.attributes && project.attributes.image" :src="project.attributes.image" class="project-img"
         alt="Project Image"/>
    <div class="container" v-if="project">

      <section class="content">


        <div class="project-details" v-if="project">


          <a class="back" href="/progetti-e-ricerche"><img style="width: 30px; transform: scaleX(-1); margin-right: 8px"
                                                           src="@/assets/slider_arrow.svg"/>Progetti e Ricerche</a>
          <div class="project-header-detail">
            <h6 style="color: limegreen; margin-bottom: 3px">PROGETTI</h6>
            <h1 style="margin-top: 3px;margin-bottom: 5px;color: #013747;">{{ project.attributes.title }}</h1>
            <h4 style="margin-top: 5px;margin-bottom: 50px;color: #013747;">{{ project.attributes.subtitle }}</h4>
            <div class="project-header-description" v-html="project.attributes.description"></div>


            <button class="di-cosa" @click="scrollToSection(2)">DI COSA SI TRATTA</button>
          </div>

          <div class="sticky-bar-fixed" v-if="project">
            <div v-for="(section, index) in sectionTitles" :key="index" class="sticky-title"
                 :class="{ active: index === activeSection }" @click="scrollToSection(index)">
              {{ section }}
            </div>
          </div>

          <div class="pr-sections">
            <div id="chi-è-coinvolto" class="section" v-if="project.attributes.chi_e_coinvolto_title">
              <h2>{{ project.attributes.chi_e_coinvolto_title }}</h2>
              <div v-html="project.attributes.chi_e_coinvolto"></div>
            </div>

            <div id="a-chi-è-rivolto" class="section" v-if="project.attributes.a_chi_e_rivolto_title">
              <h2>{{ project.attributes.a_chi_e_rivolto_title }}</h2>
              <div v-html="project.attributes.a_chi_e_rivolto"></div>
            </div>

            <div id="di-cosa-si-tratta" class="section" v-if="project.attributes.di_cosa_si_tratta_title">
              <h2>{{ project.attributes.di_cosa_si_tratta_title }}</h2>
              <div v-html="project.attributes.di_cosa_si_tratta"></div>
            </div>

            <div id="finalità" class="section" v-if="project.attributes.finalita_title">
              <h2>{{ project.attributes.finalita_title }}</h2>
              <div v-html="project.attributes.Finalita"></div>
            </div>

            <div id="obiettivi-raggiunti" class="section" v-if="project.attributes.obiettivi_raggiunti_title">
              <h2>{{ project.attributes.obiettivi_raggiunti_title }}</h2>
              <div v-html="project.attributes.obiettivi_raggiunti"></div>
            </div>

            <div id="materiali" class="section" v-if="project.attributes.materiali_title">
              <h2>{{ project.attributes.materiali_title }}</h2>
              <!-- Updated list to make materials clickable -->
              <ul class="materials-list">
                <li v-for="material in project.attributes.materiali.data" :key="material.id"
                    @click="loadMaterialDetails(material.id)" style="cursor: pointer;">
                  {{ material.attributes.title }} <img src="@/assets/icon-download.svg" alt="Related Videos Icon">
                </li>
              </ul>
            </div>

            <div id="informazioni" class="section" v-if="project.attributes.informazioni_title">
              <h2>{{ project.attributes.informazioni_title }}</h2>
              <div v-html="project.attributes.informazioni"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Correlations -->
    <div class="correlations"
         v-if="project.attributes.aree_correlate.data.length || project.attributes.corsi_correlate.data.length || project.attributes.progetti_correlate.data.length || project.attributes.ricerche_correlate.data.length">
      <h2>Guarda anche</h2>

      <div class="correlations-row">
        <div class="correlation-block" v-if="project.attributes.aree_correlate.data.length>0">
          <h3>Aree Correlate</h3>
          <div v-for="(area, index) in project.attributes.aree_correlate.data" :key="`area-${index}`"
               class="correlation-link">
            <a :href="`/areas/${area.id}`">{{ area.attributes.title }}</a>
          </div>
        </div>
        <div class="correlation-block" v-if="project.attributes.corsi_correlate.data.length>0">
          <h3>Corsi Correlati</h3>
          <div v-for="(corso, index) in project.attributes.corsi_correlate.data" :key="`corso-${index}`"
               class="correlation-link">
            <a :href="`${corso.attributes.id}`">{{ corso.attributes.title }}</a>
          </div>
        </div>
        <div class="correlation-block" v-if="project.attributes.progetti_correlate.data.length>0">
          <h3>Progetti Correlati</h3>
          <div v-for="(progetto, index) in project.attributes.progetti_correlate.data" :key="`progetto-${index}`"
               class="correlation-link">
            <a :href="`/progetti-e-ricerche/progetti/${progetto.attributes.identifier}`">{{
                progetto.attributes.title
              }}</a>
          </div>
        </div>
        <div class="correlation-block" v-if="project.attributes.ricerche_correlate.data.length>0">
          <h3>Ricerche Correlate</h3>
          <div v-for="(ricerca, index) in project.attributes.ricerche_correlate.data" :key="`ricerca-${index}`"
               class="correlation-link">
            <a :href="`/progetti-e-ricerche/ricerche/${ricerca.attributes.identifier}`">{{
                ricerca.attributes.title
              }}</a>
          </div>
        </div>
      </div>
    </div>

  </div>
  <AppBottomComponent/>
  <Footer/>
</template>

<script>
// Importing required components and services
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import {fetchSingleProject, fetchSingleMaterial} from "@/services/apiService";
import AppBottomComponent from "@/components/General/AppBottomComponent.vue";

export default {
  name: "ProjectDetailPage",
  components: {
    Header,
    Footer,
    AppBottomComponent // Registering components for use within this component
  },
  data() {
    return {
      pageTitle: 'Progetto', // Title of the page
      pageDescription: '', // Description of the page (unused placeholder)
      project: null, // Placeholder for project data
      error: null, // Placeholder for storing potential errors
      activeSection: 0, // Tracks the currently active section for highlighting
      stickyVisible: false, // Controls the visibility of the sticky navigation bar
      sectionTitles: ["Chi è coinvolto", "A chi è rivolto", "Di cosa si tratta", "Finalità", "Obiettivi raggiunti", "Materiali", "Informazioni"], // Titles of the project sections
      pageMetaTitle: '',
      pageMetaDescription: '',
    };
  },
  computed: {
    pageURL() {
      return window.location.origin + window.location.pathname;
    }
  },
  async mounted() {
    // Event listeners for scroll events to handle section highlighting and sticky bar visibility
    window.addEventListener('scroll', this.updateActiveSection);
    window.addEventListener('scroll', this.stickyBarVisibility);


    try {
      const data = await this.loadProjectData(this.$route.params.identifier);
      this.project = data; // Loading project data on component mount


      this.pageMetaTitle = this.project.attributes.meta_title || this.project.attributes.title;
      this.pageMetaDescription = this.project.attributes.meta_description || this.project.attributes.description;
      this.setMetaTags();
    } catch (error) {
      console.error('Error loading project data:', error); // Log any errors encountered
      this.error = error;
    }


  },
  beforeUnmount() {
    // Cleanup: Remove event listeners when component is about to unmount
    window.removeEventListener('scroll', this.updateActiveSection);
    window.removeEventListener('scroll', this.stickyBarVisibility);
    this.removeMetaTags();
  },
  methods: {
    async loadProjectData(identifier) {
      // Method to load project data based on the given ID
      try {
        return await fetchSingleProject(identifier);
      } catch (error) {
        console.error('Error fetching project data:', error);
        throw error;
      }
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    },
    scrollToSection(index) {
      // Scrolls to the specified section of the page
      const sectionId = this.sectionTitles[index].toLowerCase().replace(/\s+/g, '-');
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        window.scrollTo({
          top: sectionElement.offsetTop - 70, // Offset for sticky bar height
          behavior: 'smooth' // Smooth scroll to the section
        });
      }
    },
    updateActiveSection() {
      // Updates the active section based on scroll position
      let closestSectionIndex = 0;
      let smallestDifference = Infinity;
      this.sectionTitles.forEach((title, index) => {
        const sectionId = title.toLowerCase().replace(/\s+/g, '-');
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          const difference = Math.abs(sectionElement.getBoundingClientRect().top);
          if (difference < smallestDifference) {
            closestSectionIndex = index;
            smallestDifference = difference;
          }
        }
      });
      this.activeSection = closestSectionIndex;
    },
    stickyBarVisibility() {
      // Determines visibility of the sticky bar based on scroll position
      const fixedBarElement = document.querySelector('.sticky-bar-fixed');
      const stickyBarElement = document.querySelector('.sticky-bar');
      if (fixedBarElement && stickyBarElement) {
        const headerBottom = fixedBarElement.offsetHeight + fixedBarElement.offsetTop;
        this.stickyVisible = window.scrollY > headerBottom;
      }
    },
    async loadMaterialDetails(materialId) {
      // Loads details for a specific material and opens it in a new tab
      try {
        const materialDetails = await fetchSingleMaterial(materialId);
        const materialFile = materialDetails.attributes.file.data;
        if (materialFile) {
          this.downloadMaterial(materialFile.attributes.url, materialFile.attributes.name);
        } else {
          console.error('No file associated with this material.');
        }
      } catch (error) {
        console.error('Error loading material details:', error); // Log any errors encountered
      }
    },
    downloadMaterial(fileUrl, fileName) {
      // Creates a temporary link to open (or suggest download for) a file in a new tab
      const link = document.createElement('a');
      link.href = fileUrl; // File URL
      link.target = '_blank'; // Open in a new tab
      link.download = fileName || 'download'; //

      document.body.appendChild(link);
      link.click();
      // Clean up by removing the temporary link element
      document.body.removeChild(link);
    },
  }
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.project-details {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 60px;
}

.project-header-detail {
  width: 45%;
  margin-bottom: 150px;
  text-align: left;
}

project-header-detail p {
  text-align: left;
}


.sticky-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  z-index: 200;
  align-items: center;
  justify-content: center;
  display: none;
  padding: 30px 0;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  color: #495057;
}

.sticky-bar.visible {
  display: flex;
  flex-direction: row;
}

.sticky-title {
  margin: 0 10px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 20px;
  color: #013747;
  letter-spacing: 1px;
  font-weight: 400;
  white-space: nowrap;
}

.sticky-title.active {
  color: limegreen;
}

.sticky-bar-fixed {
  width: 85%;
  z-index: 200;
  display: flex;
  align-items: flex-start;
  padding: 30px 0;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  color: #495057;
  margin-bottom: 50px;
  overflow-x: auto;
}


.project-img {
  position: absolute;
  height: 720px;
  top: 190px;
  right: -250px;
  z-index: 1;
  width: 60vw;
  object-fit: cover;
  transition: all 0.5s ease;
}

.project-header-description {
  position: relative;

}

.project-header-description:after {

  content: '';
  position: absolute;
  top: -15px;
  left: -5px;
  width: 30%;
  height: 2px;
  background: linear-gradient(to left, rgba(50, 205, 50, 0), rgba(50, 205, 50, 0.6), rgba(50, 205, 50, 1) 80%, rgba(50, 205, 50, 0.5), rgba(50, 205, 50, 0));
  border-radius: 10px 10px 0 0;
}


.materials-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 95%;
}

.materials-list li {
  border-top: 1px solid black;
  padding: 14px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.2s ease;
}

.materials-list li:hover {
  color: white;
  transform: translateX(10px);

}

.materials-list li:last-child {
  border-bottom: 1px solid black;
}

/* Correlations Styling */
.correlations {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: limegreen;
  width: 100%;
}

.correlations h2 {
  color: white;
  font-family: "IBMPlexSans", sans-serif;
  font-size: 48px;
  margin-bottom: 20px;
  margin-left: 50px;
}

.correlations-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 80px;
  margin-bottom: 80px;
  justify-content: flex-start;
  gap: 20px;
}

.correlation-block {
  flex: 1 1 calc(50% - 10px);
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 10px);
}

.correlation-block h3 {
  color: #013747;
  font-size: 36px;
  padding-top: 20px;
  margin-bottom: 35px;
  border-top: 1px solid #013747;
  padding-bottom: 5px;
  color: #013747;
  letter-spacing: -0.6px;
  line-height: 29px;
  font-family: "IBMPlexSans";
}

.correlation-link {
  color: white;
  margin-bottom: 20px;
  border-bottom: dashed 1px hsl(0deg, 0%, 100%);
  padding-bottom: 15px;
}

.correlation-link a {
  color: white;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}

.correlation-link a:hover {
  color: #013747;
  font-weight: bold;
}

.back {
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  color: #013747;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 0.2s ease;
  margin-bottom: 30px;
}

.back:hover {
  transform: translateX(-10px);
}

.pr-sections {
  display: flex;
  flex-direction: column;
  gap: 50px;
}


.di-cosa {
  color: #013747;
  display: inline-flex;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 1.5px;
  position: relative;
  font-family: "MontserratSemiBold", sans-serif;
  width: auto;
  border: 1px solid #00CC00;
  padding: 21px 28px;
  transition: all 0.25s ease-out;
  cursor: pointer;
  background-color: transparent;
  margin-top: 60px;
}


@media (max-width: 992px) {
  .container, .project-details, .project-header-detail {
    padding: 0 10px;
  }

  .project-details {
    margin-top: 20px;
  }

  .project-header-detail {
    width: 95%;

  }


  .back {
    justify-content: center;
    display: none;
  }


  .sticky-bar {
    overflow-x: auto;
    align-items: flex-start;
    justify-content: left;

  }

  .sticky-bar-fixed {
    max-width: 300px;
  }

  .sticky-title {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 1.1em;
    max-height: 2.6em;
  }

  .correlation-block {
    width: 100%;
    max-width: 95%;
  }

  .correlations-row {
    flex-direction: column;
    width: 100%;
    margin-left: 5px;
  }

  .correlations h2 {
    margin-left: 5px;
  }

  .project-details h1, .project-details h2 {
    font-size: 28px;
  }

  .project-img {
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    max-height: 200px;
    margin-top: 25px;
  }

  .back {
    transform: scale(0.8);
  }
}


</style>
