<template>
  <div class="container">
    <Header :showSearchButton="true"/>
    <section class="content">
      <div class="title-box">
        <h1>{{ pageTitle }}</h1>
        <div v-html="pageDescription" class="page-description"></div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import {retrievePage} from "@/services/apiService";

export default {
  name: "ContattiPage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      pageTitle: 'Contatti',
      pageDescription: '',
      error: null,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('Contatti');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    renderPage(pageData) {
      this.pageTitle = pageData.attributes.main_title;
      this.pageDescription = pageData.attributes.description; // Assuming this is safe to render as HTML
    }
  }
}
</script>

<style scoped>

</style>
