<template>
  <div class="container">
    <Header :showSearchButton="true"/>
    <section class="content">
      <div class="title-box">
        <h1>{{ pageTitle }}</h1>
        <h2>{{ pageSubTitle }}</h2>
        <div class="page-description">{{ pageDescription }}</div>

      </div>

    </section>
    <Footer/>
  </div>
</template>


<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';


export default {
  name: "NotFoundPage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      pageTitle: '404',
      pageSubTitle: 'Qualcosa è andato storto. Scusa!',
      pageDescription: 'La pagina che hai richiesto non è stata trovata.\n' +
          'Può essere stata cancellata o è temporaneamente non disponibile.\n' +
          'Controlla per cortesia che l\'URL non abbia degli errori. Nel caso non ce li avesse, ti preghiamo di navigare nel menu o utilizzare la ricerca delle professioni per trovare il contenuto.',

    };
  },

}
</script>


<style scoped>

.page-description {
  color: #323232;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-family: "Montserrat", sans-serif;
}

</style>
