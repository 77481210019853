<template>
  <div class="container">
    <Header :showSearchButton="true"/>
    <section class="content">
      <div class="title-box">
        <h1>{{ pageTitle }}</h1>
      </div>
      <div class="sticky-bar" v-if="blocks.length" :class="{ visible: stickyVisible }">
        <div v-for="(block, index) in blocks" :key="'title' + index" class="sticky-title"
             :class="{ active: index === activeSection }" @click="scrollToSection(index)">
          {{ block.title }}
        </div>
      </div>
      <div class="title-bar" v-if="blocks.length" ref="titleBar">

        <div v-for="(block, index) in blocks" :key="'title' + index" class="bar-title"
             :class="{ active: index === activeSection }" @click="scrollToSection(index)">
          {{ block.title }}
        </div>
      </div>

      <div class="youtube-video">
        <iframe width="100%" height="100%" :src="youtubeVideoLink" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>

      <div class="sections">
        <div v-for="(block, index) in blocks" :key="index" :ref="'section' + index" class="section">
          <h2 v-if="block.title != `Progetto`">{{ block.title }}</h2>
          <div v-html="block.description"></div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import {retrievePage} from "@/services/apiService";

export default {
  name: "AboutPage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      pageTitle: 'Che cos\'è',
      blocks: [],
      youtubeVideoLink: '',
      activeSection: 0,
      stickyVisible: false,
    };
  },
  async mounted() {
    await this.loadPage();
    this.setMetaTags();
    window.addEventListener('scroll', this.updateActiveSection);
    window.addEventListener('scroll', this.stickyBarVisibility);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateActiveSection);
    window.removeEventListener('scroll', this.stickyBarVisibility);
    this.removeMetaTags();
  },
  computed: {
    pageURL() {
      return window.location.origin + window.location.pathname;
    }
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('About');
        console.log(pageData);
        if (pageData && pageData.attributes && pageData.attributes.blocks) {
          this.blocks = pageData.attributes.blocks;
          this.youtubeVideoLink = pageData.attributes.video_link;
          this.pageMetaTitle = pageData.attributes.meta_title;
          this.pageMetaDescription = pageData.attributes.meta_description;
        }
      } catch (error) {
        console.error('Error loading page:', error);
      }
    },
    scrollToSection(index) {
      const section = this.$refs[`section${index}`][0];
      if (section) {
        const offset = section.offsetTop - 80;
        window.scrollTo({top: offset, behavior: 'smooth'});
      }
    },

    updateActiveSection() {
      let closestSectionIndex = 0;
      let smallestDifference = Infinity;
      this.blocks.forEach((block, index) => {
        const section = this.$refs[`section${index}`][0];
        const difference = Math.abs(section.getBoundingClientRect().top);
        if (difference < smallestDifference) {
          closestSectionIndex = index;
          smallestDifference = difference;
        }
      });
      this.activeSection = closestSectionIndex;
    },
    stickyBarVisibility() {
      // Ensure the titleBar element is accessible and has been rendered.
      if (this.$refs.titleBar) {
        const titleBarBottom = this.$refs.titleBar.offsetTop + this.$refs.titleBar.offsetHeight; // Get the top position of the title-bar relative to the document.
        // The sticky bar becomes visible when the user scrolls past the top of the title-bar.
        this.stickyVisible = window.scrollY > titleBarBottom;
      }
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    }
  }
}
</script>


<style scoped>
.container {
  position: relative;
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1000px;
}

.youtube-video {
  margin-top: 80px;
  margin-bottom: 40px;
  width: 52%;
  height: 520px;
}

.sticky-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  z-index: 200;
  display: flex; /* Remove 'none' to allow transition */
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  overflow-x: auto;
  transform: translateY(-100%); /* Hide by default */
  transition: transform 0.3s; /* Smooth transition */
}


.sticky-title {
  margin-right: 20px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1.57px;
  white-space: nowrap;
}

.title-bar {
  color: #495057;
  width: 90%;
  max-width: 1000px;
  z-index: 200;
  border-bottom: white;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  overflow-x: auto;
}

.bar-title {
  margin: 0 8px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  width: auto;
  white-space: nowrap;
}

.sticky-bar.visible {
  transform: translateY(0); /* Show sticky bar */
}

.sticky-title.active {
  color: limegreen;
}

.bar-title.active {
  color: limegreen;
}


@media (max-width: 992px) {
  .youtube-video {
    height: 180px;
    width: 90%;
    margin-top: 20px;
  }

  .sticky-bar {
    justify-content: flex-start;
  }
}

</style>