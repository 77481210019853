<template>
  <div class="loading-overlay" v-if="isVisible">
    <img src="@/assets/Reload-1s-200px.gif" alt="Loading..." class="loading-gif">
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    // This allows the parent component to control the visibility
    isVisible: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-gif {
  /* Adjust as necessary */
  width: 110px;
  height: 110px;
}
</style>
