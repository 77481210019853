<template>
  <div>
    <div class="popup" :class="{ 'visible': isVisible }">
      <div class="popup-content" :class="{ 'visible': isVisible }">
        <div class="search-header">
          <a href="/"><img src="@/assets/logo-atlante.svg" alt="Atlante delle Professioni Logo"
                           style="height: 65px"></a>

          <button class="close-btn" @click="closePopup">
            <img src="@/assets/close-btn-icon.png" alt="Close"/>
          </button>
        </div>
        <div
            style="width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: space-between;margin-bottom: 50px">
          <input
              ref="searchInput"
              v-model="searchQuery"
              @input="filterProfessions"
              @keyup.enter="emitSearchQuery"
              type="text"
              placeholder="Cerca..."
              class="search-input"
          />
          <img style="position: absolute; right: 30px" src="@/assets/icon_search.svg" alt="Close"/>
        </div>
        <div v-if="filteredProfessions.length > 0" class="dropdown">

          <div v-for="profession in filteredProfessions" :key="profession.id" class="dropdown-item">
            <a :href="`/professioni/${profession.attributes.identifier}`">{{ profession.attributes.title }}</a>
            <div v-html="truncatedDescription(profession.attributes.identity.description)" style="overflow: hidden">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchProfessions} from "@/services/apiService";

export default {
  name: "SearchPopupComponent",
  props: {
    videoId: {
      type: String,
      required: true
    },
    isVisible: Boolean
  },
  data() {
    return {
      videoData: null, // This will store the fetched video data
      searchQuery: '',
      professions: [], // Initially empty, to be fetched on component mount or as per your logic
      filteredProfessions: [],
      animateDropdown: false,
    }
  },
  updated() {
    if (this.isVisible) {
      this.$refs.searchInput.focus();
    }
  },
  watch: {
    isVisible(newVal) {
      this.$nextTick(() => {
        if (newVal === true) {
          this.fetchProfessionsData();
          // This ensures the element is rendered and ready for transition
        }
      });
    },
  },

  methods: {
    async fetchProfessionsData() {
      this.professions = await fetchProfessions();
      console.log(this.professions)
      this.professions = this.populateDenominationsAndSpecializations(this.professions);
      console.log(this.professions);
    },
    filterProfessions() {
      if (!this.searchQuery || this.searchQuery.length < 2) {
        this.filteredProfessions = [];
        this.animateDropdown = false; // Reset animation trigger
        return;
      }
      const newFilteredProfessions = this.professions.filter(profession =>
          profession.attributes.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      ).sort((a, b) => {
        // Compare titles for sorting
        const titleA = a.attributes.title.toLowerCase();
        const titleB = b.attributes.title.toLowerCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        // names must be equal
        return 0;
      });

      if (newFilteredProfessions.length > 0 && this.filteredProfessions.length === 0) {
        // Only trigger animation if going from 0 to >0 items
        this.animateDropdown = true;
      } else {
        this.animateDropdown = false;
      }

      this.filteredProfessions = newFilteredProfessions;
    },

    emitSearchQuery() {
      this.$emit('search', this.searchQuery);
    },
    clearSearch() {
      this.searchQuery = '';
      this.filteredProfessions = [];
      this.emitSearchQuery();
    },
    closePopup() {
      this.$emit('close-popup');
    },
    populateDenominationsAndSpecializations(professions) {
      const newProfessions = [];

      professions.forEach(profession => {
        // Check if the denominazione array exists and is not empty
        if (profession.attributes.denominazione && profession.attributes.denominazione.length > 0) {
          profession.attributes.denominazione.forEach(denomination => {
            if (denomination.title) {
              let description = `<a style="margin-top: 15px">Altra denominazione di <span style="font-weight:bold;">${profession.attributes.title}</span></a>`;
              if (denomination.specializzazione) {
                description = `<a style="margin-top: 15px">Specializzazione di <span style="font-weight:bold;">${profession.attributes.title}</span></a>`;
              }

              // Create a new profession object for each denomination
              const newProfession = {
                id: profession.id,
                attributes: {
                  identifier: profession.attributes.identifier,
                  title: denomination.title,
                  identity: {
                    description: description
                  }
                }
              };
              newProfessions.push(newProfession);
            }
          });
        }
      });

      // Concatenate the new professions to the original professions array
      Array.prototype.push.apply(professions, newProfessions);
      return professions;
    }

  },
  computed: {
    // Truncate description if it exceeds 100 characters
    truncatedDescription() {
      return description => {
        const maxLength = 300;
        if (description.length > maxLength) {
          return description.slice(0, maxLength) + '...';
        } else {
          return description;
        }
      };
    }
  }
  ,
}
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  visibility: hidden;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  opacity: 0;
  transition: visibility 0s linear 0.4s, opacity 0.4s linear;
}

.popup.visible {
  display: flex;
  visibility: visible;
  opacity: 1;
  display: flex; /* Ensure this stays to keep the layout */
  transition-delay: 0s; /* Apply transition delay reset for visibility */
}

.popup-content {
  visibility: hidden;
  flex-direction: column; /* Changed to column for better layout control */
  justify-content: start; /* Align content to the start */
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  width: 60%;
  max-width: 1100px;
  position: relative; /* For dropdown positioning */
  height: 90%;
  z-index: 2001;
  transform: scale(0.5); /* Start scaled down */
  opacity: 0; /* Start invisible */
  transition: transform 1s ease, opacity 0.4s ease;
}

.popup-content.visible {
  visibility: visible;
  display: flex;
  transform: scale(1); /* Scale to full size */
  opacity: 1; /* Fully opaque */
}

.search-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
}

.close-btn {
  border: none;
  cursor: pointer;
  background-color: white;
}

.close-btn img {
  height: 30px;
}

.search-input {
  height: 50px;
  border: none;
  border-bottom: 2px solid limegreen;
  outline: none;
  font-size: 24px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

.search-input::placeholder {
  font-size: 24px; /* Adjust the font size as needed */
  font-family: "Montserrat", sans-serif;
  color: #013747;
}

.dropdown {
  display: flex;
  flex-direction: column;

  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  flex-direction: column;
  border-bottom: 2px dotted limegreen;
  max-height: 130px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dropdown-item a {
  text-decoration: none;
  font-weight: bold;
  font-size: 22px;
}


@media (max-width: 992px) {
  .popup-content {
    width: 90%;
  }

}


</style>
