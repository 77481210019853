<template>
  <div class="bottom-component">
    <div class="video-section" v-if="!hideVideo">
      <div class="video-section-text">
        <div>
          <h2>{{ video_title }}</h2>
          <p>{{ video_subtitle }}</p>
        </div>
        <button class="bc-button" @click="watchVideo"><b>GUARDA I VIDEO</b></button>
      </div>
    </div>
    <div class="statistics-section" :class="{ 'full-width': hideVideo }">
      <div class="statistics-section-text">
        <div>
          <h2>{{ statistics_title }}</h2>
          <p>{{ statistics_subtitle }}</p>
        </div>
        <button class="bc-button" @click="viewStatistics"><b>GUARDA LE STATISTICHE</b>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {retrievePage} from "@/services/apiService";

export default {
  name: "AppBottomComponent",
  props: {
    hideVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      video_title: 'Video',
      video_subtitle: '',
      statistics_title: 'Statistiche',
      statistics_subtitle: '',
      error: null,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    watchVideo() {
      this.$router.push('/video');
    },
    viewStatistics() {
      window.open("https://statistiche.atlantedelleprofessioni.it/", "_blank");
    },
    async loadPage() {
      try {
        const pageData = await retrievePage('Home');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    renderPage(pageData) {
      this.video_title = pageData.attributes.bottom_mini_blocks[0].title;
      this.video_subtitle = pageData.attributes.bottom_mini_blocks[0].subtitle;
      this.statistics_title = pageData.attributes.bottom_mini_blocks[1].title;
      this.statistics_subtitle = pageData.attributes.bottom_mini_blocks[1].subtitle;
    }
  }
}
</script>

<style scoped>
.bottom-component {
  display: flex;
  justify-content: space-evenly;
  background-color: #003366; /* Example color */
  color: white;
  height: auto; /* Changed to auto for responsiveness */
  z-index: 10;
}

.bottom-component h2 {
  font-size: 24px;
}

.bottom-component p {
  font-size: 16px;
}

.bottom-component button {
  background-color: transparent;
  border: 1px solid limegreen;
  color: white;
  font-family: "Montserrat";
  padding: 10px 20px;
  cursor: pointer;
}

.video-section, .statistics-section {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px 50px 50px 50px;
}

.video-section h2, .statistics-section h2 {
  font-size: 48px;
}

.video-section {
  background-image: url("@/assets/video_bg.82cb0903.jpg");
  background-size: cover;
  background-position: center;
  align-items: flex-end;
}

.video-section-text, .statistics-section-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  height: 300px;
  margin-bottom: 60px;
}

.statistics-section {
  align-items: flex-start;
  background-image: url("@/assets/statistiche_bg.44bf4393.jpg");
  background-size: cover;
  background-position: center;
}

.bc-button {
  width: 55%;
  height: 50px;
  font-size: 12px;
}

.statistics-section.full-width {
  width: 100%;
  padding-left: 250px;
}

@media (max-width: 992px) {
  .bottom-component {
    flex-direction: column;
    align-items: center;
  }

  .video-section, .statistics-section {
    width: 100%;
    padding: 0;
    align-items: center;
  }

  .video-section-text, .statistics-section-text {
    width: 80%;
    align-items: center;
    margin-bottom: 30px;
  }

  .video-section h2, .statistics-section h2 {
    font-size: 36px;
    width: 100%;
  }

  .bottom-component p {
    font-size: 14px;
  }

  .bottom-component button {
    width: 90%;
  }

  .statistics-section.full-width {
    width: 100%;
    padding-left: 0px;
  }
}


</style>
