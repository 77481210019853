<template>
  <div class="profession-slider-component">
    <div v-if="error">{{ error }}</div>
    <div v-else class="slider">

      <div v-for="(profession, index) in professions" :key="profession.id" class="slide"
           :class="{'active': currentSlideIndex === index}">

        <div class="intro-block">
          <h1 class="little-header">PROFESSIONI</h1>
          <a class="profession-title" :href="`/professioni/${profession.attributes.identifier}`"
             style="text-decoration: none">
            <h2 class="profession-title">{{ profession.attributes.title }}</h2>

          </a>
          <div class="profession-description" v-html="profession.attributes.identity.description"></div>
          <div style="margin-left: 100px">
            <button @click="prevSlide" class="slider-button prev"><img style="width: 30px; transform: scaleX(-1);"
                                                                       src="@/assets/slider_arrow.svg" alt="prev"/>
            </button>
            <button @click="nextSlide" class="slider-button next"><img style="width: 30px;"
                                                                       src="@/assets/slider_arrow.svg" alt="next"/>

            </button>
          </div>
        </div>

        <img v-if="profession.attributes && profession.attributes.identity.image.data"
             :src="getImageFileUrl(profession.attributes.identity.image.data.attributes.url)"
             alt="Profession Image" class="profession-image-slider"/>

        <div class="slider-pagination">
          <div v-for="index in professions.length" :key="index" class="slider-page"
               :class="{'active': currentSlideIndex === index-1}" @click="setSlide(index-1)"></div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
import {retrievePage} from "@/services/apiService";

export default {
  name: "ProfessionSliderComponent",
  data() {
    return {
      professions: [],
      currentSlideIndex: 0,
      error: null,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('Home');

        this.professions = pageData.attributes.profession_previews.data.map(profession => ({
          ...profession,
          attributes: {
            ...profession.attributes,
            identity: {
              ...profession.attributes.identity,
              description: this.truncateText(profession.attributes.identity.description, 210) // Assuming 250 characters as max
            }
          }
        }));
        console.log(this.professions);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    prevSlide() {
      if (this.currentSlideIndex === 0) {
        this.currentSlideIndex = this.professions.length - 1;
      } else {
        this.currentSlideIndex--;
      }
    },
    nextSlide() {
      if (this.currentSlideIndex === this.professions.length - 1) {
        this.currentSlideIndex = 0;
      } else {
        this.currentSlideIndex++;
      }
    },
    getImageFileUrl(url) {

      const imageLink = `${process.env.VUE_APP_BASE_BACK_URL}${url}`;
      return imageLink;
    },
    setSlide(index) {
      this.currentSlideIndex = index;
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + '...';
      }
      return text;
    },
  }
}
</script>


<style scoped>
.profession-slider-component .slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 644px;
}

.profession-slider-component .slide {
  display: none;
  position: relative;
}

.profession-slider-component .slide.active {
  width: 100%;
  display: block;
}

.intro-block {
  position: absolute;
  box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.27);
  background-color: #3BFF3B;
  width: 280px;
  height: 440px;
  bottom: inherit;
  padding: 40px 60px;
  left: 16vw;
  right: auto;
  z-index: 100;
  display: flex; /* Use flexbox */
  flex-direction: column; /* Align items vertically */
  align-items: flex-end;
  top: -50px;
}

.profession-image-slider {
  width: auto;
  min-width: 100%;
  height: 1000px;
  object-fit: cover;
  aspect-ratio: auto 1600 / 800;
  z-index: 0;
}

.profession-description {
  overflow: hidden; /* Hide overflow when description is collapsed */
  max-height: 300px;
  position: relative;
  width: 100%;
  transition: all 0.5s ease;
  margin-bottom: 40px;
}

.slider-button {
  border: none;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;

}

.little-header {
  color: darkslategray;
  width: 100%;
  font-family: "Montserrat";
  font-size: 11px;
  color: #013747;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.profession-title {
  width: 100%;
  font-size: 36px;
  line-height: 35px;
  margin-top: 5px;
  color: #323232;
  text-align: left;

}

.slider-pagination {
  position: absolute;
  top: 580px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  z-index: 20;
}

.slider-page {
  width: 180px; /* Tiny rectangle width */
  height: 5px; /* Tiny rectangle height */
  background-color: rgba(204, 204, 204, 0.5); /* Default color */
  margin: 0 5px; /* Spacing between buttons */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.slider-page.active {
  background-color: #3BFF3B; /* Green color when active */
}

@media (max-width: 992px) {
  .intro-block {
    position: absolute;
    width: 250px;
    height: 440px;
    bottom: inherit;
    padding: 40px 40px;
    left: 6vw;
    top: 60px;
    align-items: flex-start;
  }


  .slider-page {
    width: 25px; /* Tiny rectangle width */
    height: 7px; /* Tiny rectangle height */
  }

  .profession-title {
    font-family: "IBMPlexSans", sans-serif;
    font-size: 32px;
    color: #013747;
    letter-spacing: -0.63px;
    line-height: 33px;
  }

  .profession-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.3px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
}

</style>
