<template>
  <div class="container">
    <Header :showSearchButton="true"/>

    <section class="content-courses">

      <div class="title-box">
        <div style="max-width: 600px">
          <h1>{{ pageTitle }}</h1>
          <p>{{ pageSubtitle }}</p>
        </div>
      </div>

      <div class="legend-container">
        <IconsLegendComponent/>
      </div>
      <LoadingComponent :is-visible="isLoading" style="margin-top: 20px; margin-bottom: 100px"></LoadingComponent>
      <div class="all-academic-courses">
        <section v-for="(categories, generalArea) in degreeCourses" :key="generalArea">
          <h2 class="general-area">{{ generalArea }}</h2>
          <div v-for="(areas, academicTitle) in categories" :key="academicTitle">

            <h3
                class="academic-category"
                :class="{ 'is-expanded': isExpanded(generalArea + '_' + academicTitle) }"
                @click="toggleAcademicCategory(generalArea, academicTitle)"
            >
              {{ academicTitle }}
              <img v-if="!isExpanded(generalArea + '_' + academicTitle)" src="@/assets/icon_plus.svg" class="video-icon"
                   alt="Video Icon"/>
              <img v-if="isExpanded(generalArea + '_' + academicTitle)" src="@/assets/icon_minus.svg" class="video-icon"
                   alt="Video Icon"/></h3>


            <div v-if="isExpanded(generalArea + '_' + academicTitle)">
              <div v-for="(courses, courseType) in areas" :key="courseType">
                <h4
                    class="course-type"
                    @click="toggleProfessionalArea(generalArea, academicTitle, courseType)"
                >
                  Lauree {{ mapCourseTypeToDisplayText(courseType) }}
                  <img v-if="!courses.expanded" src="@/assets/arrow_bottom.svg" class="video-icon" alt="Video Icon"/>
                  <img v-if="courses.expanded" src="@/assets/arrow_top.svg" class="video-icon" alt="Video Icon"/></h4>
                <ul v-if="courses.expanded">
                  <li class="course-item" v-for="course in courses.list" :key="course.id">
                    <h5><a :href="`${course.link}`" target="_blank">{{ course.title }} </a><img
                        style="margin-left: 15px"
                        src="@/assets/icon_linkbee.svg"
                        class="video-icon" alt="Video Icon"/>
                    </h5>
                    <ul>
                      <li class="profession-item" v-for="profession in course.relatedProfessions" :key="profession.id">
                        <div class="profession-item-info">
                          <div>
                            <a style="align-items: center"
                               :href="`/professioni/${profession.identifier}`">{{ profession.title }}
                              <img style="margin-left: 12px" src="@/assets/arrow_right.svg" class="arrow-icon"
                                   alt="Video Icon"/></a>
                          </div>
                          <div style="display: flex; flex-direction: row; gap: 10px; align-items: center">
                            <InfoIcon v-if="profession.hasVideos" type="related_videos"></InfoIcon>
                            <InfoIcon v-if="profession.foglia_bianca" type="foglia_bianca"></InfoIcon>
                            <InfoIcon v-if="profession.foglia_verde" type="foglia_verde"></InfoIcon>
                          </div>
                        </div>
                        <div v-for="denomination in profession.denominazione" :key="denomination.id">
                          <div v-if="denomination.specializzazione">
                            <a :href="`/professioni/${profession.identifier}`"
                               class="specializations">{{
                                denomination.title
                              }}</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import IconsLegendComponent from '@/components/General/IconsLegendComponent.vue';
import InfoIcon from '@/components/General/InfoIcon.vue';
import {fetchCourses, retrievePage} from "@/services/apiService";
import LoadingComponent from "@/components/General/LoadingScreenComponent.vue";

export default {
  name: "AcademicCoursesPage",
  components: {
    Header,
    Footer,
    InfoIcon,
    LoadingComponent,
    IconsLegendComponent
  },
  data() {
    return {
      pageTitle: 'Corsi di laurea',
      pageSubtitle: '',
      degreeCourses: {},
      expandedAreas: {},
      courseTypeOrder: ['Triennale', 'Ciclo unico', 'Specialistica'],
      categoryOrder: ['Scienze matematiche, fisiche, naturali', 'Scienze della vita', 'Scienze motorie e discipline dei servizi alla persona', 'Scienze della salute', 'Scienze economiche e gestionali', 'Scienze giuridiche', 'Scienze sociali', 'Discipline letterarie, linguistiche, e artistico-espressive', 'Scienze della formazione e dell\'educazione'],
      isLoading: true,
      pageMetaTitle: 'Corsi di laurea | Atlante Delle Professioni',
      pageMetaDescription: '',
    };
  },
  async mounted() {
    try {
      await this.loadPage();
      this.setMetaTags();
      const rawData = await fetchCourses();
      this.degreeCourses = this.transformData(rawData);
      this.isLoading = false;
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  },
  beforeUnmount() {
    this.removeMetaTags();
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('DegreeCourses');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
      }
    },
    renderPage(pageData) {
      this.pageTitle = pageData.attributes.main_title;
      this.pageSubtitle = pageData.attributes.main_subtitle;
      this.generalAreaOrder = pageData.attributes.general_area;
      if (this.generalAreaOrder) {
        this.generalAreaOrder = this.generalAreaOrder.map(object => object.title);
      }
      this.pageMetaTitle = pageData.attributes.meta_title;
      this.pageMetaDescription = pageData.attributes.meta_description;
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    },
    transformData(data) {
      let transformed = {};
      data.forEach((course) => {
        try {
          const generalArea = course.attributes.academic_category.data.attributes.general_area;
          const academicTitle = course.attributes.academic_category.data.attributes.title;
          const courseType = course.attributes.type;

          if (!transformed[generalArea]) transformed[generalArea] = {};
          if (!transformed[generalArea][academicTitle]) transformed[generalArea][academicTitle] = {};
          if (!transformed[generalArea][academicTitle][courseType]) {
            transformed[generalArea][academicTitle][courseType] = {list: [], expanded: false};
          }

          const relatedProfessions = [];
          course.attributes.professions.data.forEach(profession => {
            relatedProfessions.push({
              id: profession.id,
              identifier: profession.attributes.identifier,
              title: profession.attributes.title,
              foglia_verde: profession.attributes.foglia_verde,
              foglia_bianca: profession.attributes.foglia_bianca,
              hasVideos: profession.attributes.hasVideos,
              denominazione: profession.attributes.denominazione
            });
          });


          relatedProfessions.sort((a, b) => a.title.localeCompare(b.title));

          transformed[generalArea][academicTitle][courseType].list.push({
            id: course.id,
            title: course.attributes.title,
            link: course.attributes.link,
            relatedProfessions,
          });
        } catch (error) {
          console.error(`Error processing area with id ${course.id}:`, error.message);
        }
      });

      transformed = this.sortObjectByKeys(transformed);

      Object.keys(transformed).forEach(generalArea => {
        const sortedCategories = {};
        this.categoryOrder.forEach(category => {
          if (transformed[generalArea][category]) {
            sortedCategories[category] = transformed[generalArea][category];
          }
        });
        Object.keys(transformed[generalArea]).forEach(category => {
          if (!sortedCategories[category]) {
            sortedCategories[category] = transformed[generalArea][category];
          }
        });
        transformed[generalArea] = sortedCategories;
      });

      Object.keys(transformed).forEach(generalArea => {
        Object.keys(transformed[generalArea]).forEach(academicTitle => {
          const sortedAreas = {};
          this.courseTypeOrder.forEach(type => {
            if (transformed[generalArea][academicTitle][type]) {
              sortedAreas[type] = transformed[generalArea][academicTitle][type];
            }
          });
          Object.keys(transformed[generalArea][academicTitle]).forEach(type => {
            if (!sortedAreas[type]) {
              sortedAreas[type] = transformed[generalArea][academicTitle][type];
            }
          });
          transformed[generalArea][academicTitle] = sortedAreas;
        });
      });

      const sortedTransformed = {};
      if (this.generalAreaOrder && this.generalAreaOrder.length > 0) {
        this.generalAreaOrder.forEach((order) => {
          if (transformed[order]) {
            sortedTransformed[order] = transformed[order];
          }
        });
        Object.keys(transformed).forEach((key) => {
          if (!sortedTransformed[key]) {
            sortedTransformed[key] = transformed[key];
          }
        });
      } else {
        return transformed;
      }

      return sortedTransformed;
    },

// Helper function to sort objects by their keys
    sortObjectByKeys(obj) {
      const sortedObj = {};
      Object.keys(obj).sort().forEach(key => {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          sortedObj[key] = this.sortObjectByKeys(obj[key]); // Recurse for nested objects
        } else if (Array.isArray(obj[key])) {
          sortedObj[key] = obj[key].sort((a, b) => a.title.localeCompare(b.title)); // Sort arrays by title
        } else {
          sortedObj[key] = obj[key];
        }
      });
      return sortedObj;
    }
    ,
    toggleAcademicCategory(generalArea, academicTitle) {
      const key = `${generalArea}_${academicTitle}`;
      this.expandedAreas[key] = !this.expandedAreas[key];
    }
    ,
    toggleProfessionalArea(generalArea, academicTitle, courseType) {
      const area = this.degreeCourses[generalArea][academicTitle][courseType];
      if (area) {
        area.expanded = !area.expanded;
      }
    }
    ,

    isExpanded(key) {
      return !!this.expandedAreas[key];
    }
    ,
    mapCourseTypeToDisplayText(courseType) {
      switch (courseType) {
        case 'Triennale':
          return 'Triennali';
        case 'Ciclo unico':
          return 'Ciclo Unico';
        case 'Specialistica':
          return 'Magistrali';
        default:
          return courseType; // Return as is if no match
      }
    }
    ,
  },
}
</script>


<style scoped>

.content-courses {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.all-academic-courses {
  margin-top: 5px;
  width: 60%;
  max-width: 1000px;
}


.general-area {
  font-size: 45px;
  color: white;
}

.academic-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid white;
  transition: border-color 0.3s;
  font-size: 30px;
  color: #013747;
  letter-spacing: -0.6px;
  line-height: 32px;
}

.academic-category.is-expanded {
  border-bottom: 1px solid #013747;
}

.course-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-bottom: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dotted #013747;
  color: #013747;
  font-size: 25px;
  letter-spacing: -0.5px;
  line-height: 34px;
  font-family: "IBMPlexSans";
}


.course-item {
  padding-left: 50px;
  transition: all 0.1s ease;
  color: #013747;
  border-bottom: 1px dotted #013747;
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 24px;
  font-family: "IBMPlexSans";
}


.course-item a {
  text-decoration: none;
  color: inherit;
  font-size: 18px;
  font-weight: bold;
}

.course-item a:hover {
  color: white;
  transform: translateX(5px);
}

.course-item a:hover {
  text-decoration: none;
}

.profession-item {
  padding-left: 60px;
  transition: all 0.1s ease;
  color: #013747;
  border-bottom: 1px dotted white;
  margin-bottom: 5px;
  letter-spacing: -0.4px;
  line-height: 24px;
  font-family: "IBMPlexSans";
}


.profession-item:last-child {
  border-bottom: none;
  margin-bottom: 25px;
}

.profession-item-info a {
  text-decoration: none;
  font-size: 18px;
  color: inherit;
  font-family: "IBMPlexSans";

}


.profession-item-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  transition: all 0.1s ease;
  color: #013747;
  margin-bottom: 5px;
  letter-spacing: -0.4px;
  line-height: 24px;
  font-family: "IBMPlexSans";
}

.profession-item-info:hover {
  color: white;
  transform: translateX(5px);

}

.specializations {
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
  transition: all 0.1s ease;
  color: #013747;
  margin-bottom: 3px;
  letter-spacing: -0.4px;
  line-height: 24px;
  text-decoration: none;
  font-family: "IBMPlexSans";
}

.specializations:hover {
  color: white;
  transform: translateX(5px);
}

hr {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  padding: 5px 0;
}

h3, h4 {
  cursor: pointer;
  user-select: none;
}

.legend-container {
  display: none;
}


.expand-enter-active, .expand-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.expand-enter, .expand-leave-to /* .expand-leave-active in <2.1.8 */
{
  max-height: 0;
}


@media (max-width: 992px) {

  .general-area {
    font-family: "IBMPlexSans";
    font-size: 32px;
    color: hsl(0deg, 0%, 100%);
    letter-spacing: -0.63px;
    line-height: 33px;
  }

  .academic-category {
    font-size: 20px;
    color: #013747;
    letter-spacing: -0.4px;
    line-height: 23px;
    font-family: "IBMPlexSans";
    gap: 15px;
  }

  .all-academic-courses {
    margin-top: 1px;
    width: 85%;
  }

  .course-type {
    padding-left: 10px;
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 21px;
    font-family: "IBMPlexSans";
  }

  .course-item {
    padding-left: 20px;
    font-size: 16px;
  }

  .course-item a {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 22px;
    font-family: "IBMPlexSans";
  }

  .profession-item {
    padding-left: 30px;
  }

  .profession-item a {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 22px;
    font-family: "IBMPlexSans";
  }

  .arrow-icon {
    display: none;
  }

  .legend-container {
    display: block;
  }
}

</style>
