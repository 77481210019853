<template>
  <Header :showSearchButton="true"/>
  <div class="sticky-bar" v-if="research" :class="{ visible: stickyVisible }">
    <div v-for="(section, index) in sectionTitles" :key="index" class="sticky-title"
         :class="{ active: index === activeSection }" @click="scrollToSection(index)">
      {{ section }}
    </div>
  </div>
  <div class="main-container" v-if="research">
    <img v-if="research.attributes && research.attributes.image" :src="research.attributes.image"
         class="research-img"
         alt="Research Image"/>
    <div class="container">

      <section class="content">
        <div class="research-details" v-if="research">

          <a class="back" href="/progetti-e-ricerche"><img style="width: 30px; transform: scaleX(-1); margin-right: 8px"
                                                           src="@/assets/slider_arrow.svg"/>Progetti e Ricerche</a>
          <div class="research-header-detail" :class="{ 'is-expanded': isFullDescriptionVisible }">
            <h6 style="color: #013747; margin-bottom: 3px">RICERCHE</h6>
            <h1 class="research-title">{{ research.attributes.title }}</h1>
            <h4 class="research-description">{{ research.attributes.subtitle }}</h4>
            <div v-if="research.attributes.description"
                 style="display: flex; flex-direction: column;align-items: flex-start">
              <div class="research-header-description" v-html="displayDescription"
                   :class="{ 'is-description-expanded': isFullDescriptionVisible }"></div>

              <button @click="toggleDescription" class="read-more-button"
                      v-if="research.attributes.description">
                <a>{{ isFullDescriptionVisible ? 'Leggi meno' : 'Leggi Tutto' }}</a>
              </button>

            </div>

            <button class="di-cosa" @click="scrollToSection(1)">DI COSA SI TRATTA</button>
          </div>

          <div class="sticky-bar-fixed" v-if="research">
            <div v-for="(section, index) in sectionTitles" :key="index" class="sticky-title"
                 :class="{ active: index === activeSection }" @click="scrollToSection(index)">
              {{ section }}
            </div>
          </div>

          <div id="gruppo-di-ricerca" class="section" v-if="research.attributes.gruppo_di_ricerca_title">
            <h2>{{ research.attributes.gruppo_di_ricerca_title }}</h2>
            <div v-html="research.attributes.gruppo_di_ricerca" class="richtext"></div>
          </div>

          <div id="di-cosa-si-tratta" class="section" v-if="research.attributes.di_cosa_si_tratta_title">
            <h2>{{ research.attributes.di_cosa_si_tratta_title }}</h2>
            <div v-html="research.attributes.di_cosa_si_tratta" class="richtext"></div>
          </div>

          <div id="metodologia-della-ricerca" class="section"
               v-if="research.attributes.metodologia_della_ricerca_title">
            <h2>{{ research.attributes.metodologia_della_ricerca_title }}</h2>
            <div v-html="research.attributes.metodologia_della_ricerca" class="richtext"></div>
          </div>

          <div id="prodotti-della-ricerca" class="section" v-if="research.attributes.prodotti_della_ricerca_title">
            <h2>{{ research.attributes.prodotti_della_ricerca_title }}</h2>
            <div v-html="research.attributes.prodotti_della_ricerca" class="richtext"></div>
          </div>

          <div id="informazioni-title" class="section" v-if="research.attributes.informazioni_title">
            <h2>{{ research.attributes.informazioni_title }}</h2>
            <div v-html="research.attributes.informazioni" class="richtext"></div>
          </div>


        </div>
      </section>

    </div>
    <!-- Simplify the correlations section to a single row that adjusts based on content -->
    <div class="correlations">
      <h2>Guarda anche</h2>
      <div class="correlations-row">
        <div class="correlation-block" v-if="research.attributes.aree_correlate.data.length>0">
          <h3>Aree Correlate</h3>
          <div v-for="(area, index) in research.attributes.aree_correlate.data" :key="`area-${index}`"
               class="correlation-link">
            <a :href="`/areas/${area.id}`">{{ area.attributes.title }}</a>
          </div>
        </div>
        <div class="correlation-block" v-if="research.attributes.corsi_correlate.data.length>0">
          <h3>Corsi Correlati</h3>
          <div v-for="(corso, index) in research.attributes.corsi_correlate.data" :key="`corso-${index}`"
               class="correlation-link">
            <a :href="`${corso.attributes.link}`">{{ corso.attributes.title }}</a>
          </div>
        </div>
        <div class="correlation-block" v-if="research.attributes.progetti_correlate.data.length>0">
          <h3>Progetti Correlati</h3>
          <div v-for="(progetto, index) in research.attributes.progetti_correlate.data" :key="`progetto-${index}`"
               class="correlation-link">
            <a :href="`/progetti-e-ricerche/progetti/${progetto.attributes.identifier}`">{{
                progetto.attributes.title
              }}</a>
          </div>
        </div>
        <div class="correlation-block" v-if="research.attributes.ricerche_correlate.data.length>0">
          <h3>Ricerche Correlate</h3>
          <div v-for="(ricerca, index) in research.attributes.ricerche_correlate.data" :key="`ricerca-${index}`"
               class="correlation-link">
            <a :href="`/progetti-e-ricerche/ricerche/${ricerca.attributes.identifier}`">{{
                ricerca.attributes.title
              }}</a>
          </div>
        </div>
      </div>
    </div>


  </div>
  <AppBottomComponent/>
  <Footer/>
</template>

<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import {fetchSingleResearch} from "@/services/apiService";
import AppBottomComponent from "@/components/General/AppBottomComponent.vue";

export default {
  name: "ResearchDetailPage",
  components: {
    Header,
    Footer,
    AppBottomComponent
  },
  data() {
    return {
      pageTitle: 'Ricerca',
      pageDescription: '',
      research: null, // Initialize research data
      error: null,
      activeSection: 0,
      stickyVisible: false,
      isFullDescriptionVisible: false,
      sectionTitles: [
        "Gruppo di ricerca",
        "Di cosa si tratta",
        "Metodologia della ricerca",
        "Prodotti della ricerca",
        "Informazioni"
      ],
      pageMetaTitle: '',
      pageMetaDescription: '',
    };
  },

  async mounted() {
    window.addEventListener('scroll', this.updateActiveSection);
    window.addEventListener('scroll', this.stickyBarVisibility);

    try {
      const data = await this.loadResearchData(this.$route.params.identifier);
      this.research = data;

      this.pageMetaTitle = this.research.attributes.meta_title || this.research.attributes.title;
      this.pageMetaDescription = this.research.attributes.meta_description || this.research.attributes.description;
      this.setMetaTags();
    } catch (error) {
      console.error('Error loading research data:', error);
      this.error = error;
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateActiveSection);
    window.removeEventListener('scroll', this.stickyBarVisibility);
    this.removeMetaTags();
  },
  methods: {
    async loadResearchData(identifier) {
      try {
        return await fetchSingleResearch(identifier);
      } catch (error) {
        console.error('Error fetching research data:', error);
        throw error;
      }
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    },
    scrollToSection(index) {
      const sectionId = this.sectionTitles[index].toLowerCase().replace(/\s+/g, '-');
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        window.scrollTo({
          top: sectionElement.offsetTop - 70, // Consider sticky bar height
          behavior: 'smooth'
        });
      }
    },
    updateActiveSection() {
      let closestSectionIndex = 0;
      let smallestDifference = Infinity;
      this.sectionTitles.forEach((title, index) => {
        const sectionId = title.toLowerCase().replace(/\s+/g, '-');
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          const difference = Math.abs(sectionElement.getBoundingClientRect().top);
          if (difference < smallestDifference) {
            closestSectionIndex = index;
            smallestDifference = difference;
          }
        }
      });
      this.activeSection = closestSectionIndex;
    },
    stickyBarVisibility() {
      const fixedBarElement = document.querySelector('.sticky-bar-fixed');
      const stickyBarElement = document.querySelector('.sticky-bar');
      if (fixedBarElement && stickyBarElement) {
        const headerBottom = fixedBarElement.offsetHeight + fixedBarElement.offsetTop;
        this.stickyVisible = window.scrollY > headerBottom;
      }
    },
    toggleDescription() {
      this.isFullDescriptionVisible = !this.isFullDescriptionVisible;
    },
    truncateText(text, limit) {
      if (text.length > limit) {
        return text.substring(0, limit) + ' ... ';
      }
      return text;
    },
  },
  computed: {
    displayDescription() {
      if (!this.research || !this.research.attributes.description) return '';
      return this.isFullDescriptionVisible
          ? this.research.attributes.description
          : this.truncateText(this.research.attributes.description, 750);
    },

    pageURL() {
      return window.location.origin + window.location.pathname;
    }

  }
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.research-details {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
}

.research-header-detail {
  width: 45%;
  margin-bottom: 150px;
  z-index: 2;
  background-color: lime;
  padding: 60px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.is-expanded {
  width: 70%;
}


.back {
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  color: #013747;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 0.2s ease;
  margin-bottom: 5px;
  margin-top: 30px;
  z-index: 10;
}

.back:hover {
  transform: translateX(-10px);
}

.sticky-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  z-index: 200;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  color: #495057;
  overflow-x: auto;
}

.sticky-bar.visible {
  display: flex;
  flex-direction: row;
}


.sticky-title {
  margin: 0 10px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 20px;
  color: #013747;
  letter-spacing: 1px;
  font-weight: 400;
  white-space: nowrap;
}

.sticky-title.active {
  color: limegreen;
}

.sticky-bar-fixed {
  width: 80%;
  z-index: 200;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  color: #495057;
  margin-bottom: 50px;
  overflow-x: auto;
}

.research-img {
  position: absolute;
  width: 100%;
  height: 900px;
  top: 190px;
  z-index: 1;
  object-fit: cover;
  transition: all 0.5s ease;
}

.di-cosa {
  color: #013747;
  display: inline-flex;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 1.5px;
  position: relative;
  font-family: "MontserratSemiBold", sans-serif;
  width: auto;
  border: 1px solid #00CC00;
  padding: 21px 28px;
  transition: all 0.25s ease-out;
  cursor: pointer;
  background-color: transparent;
  margin-top: 60px;
}

.research-title {
  font-family: "IBMPlexSans";
  font-size: 38px;
  color: #013747;
  letter-spacing: -0.63px;
  line-height: 33px;
  padding: 10px 0 20px;
  position: relative;
  display: block;
  border: none;
  margin-bottom: 0;
}

.research-description {
  position: relative;
  color: #013747;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 50px;
  font-family: "Montserrat";
  font-weight: normal;
  margin-top: 3px;
  transition: all 0.5s ease;
}

.research-header-description {
  position: relative;
  max-height: 320px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.is-description-expanded {
  max-height: 1500px;
}

.read-more-button {
  background-color: transparent;

  border: none;
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;

}

.read-more-button a {
  color: #013747; /* Match theme color */
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.read-more-button a:hover {
  border-bottom: 1px solid white;
}

.section h2 {
  font-family: "IBMPlexSans";
  font-size: 28px;
  color: #013747;
  letter-spacing: -0.6px;
  line-height: 29px;
}

.research-header-description:after {
  content: '';
  position: absolute;
  top: -15px;
  left: -5px;
  width: 30%;
  height: 2px; /* The thickness of your border */
  background: linear-gradient(to left, rgba(50, 205, 50, 0), rgba(50, 205, 50, 0.6), rgba(50, 205, 50, 1) 80%, rgba(50, 205, 50, 0.5), rgba(50, 205, 50, 0));
  border-radius: 10px 10px 0 0; /* Only the top corners rounded */
}


/* Correlations Styling */
.correlations {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: limegreen;
  width: 100%;
}

.correlations h2 {
  color: white;
  font-family: "IBMPlexSans", sans-serif;
  font-size: 48px;
  margin-bottom: 20px;
  margin-left: 50px;
}

.correlations-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 80px;
  margin-bottom: 80px;
  justify-content: flex-start;
  gap: 20px;
}

.correlation-block {
  flex: 1 1 calc(50% - 10px);
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 10px);
}


.correlation-block h3 {
  color: #013747;
  font-size: 36px;
  padding-top: 20px;
  margin-bottom: 35px;
  border-top: 1px solid #013747;
  padding-bottom: 5px;
  color: #013747;
  letter-spacing: -0.6px;
  line-height: 29px;
  font-family: "IBMPlexSans";
}

.correlation-link {
  color: white;
  margin-bottom: 20px;
  border-bottom: dashed 1px hsl(0deg, 0%, 100%);
  padding-bottom: 15px;
}

.correlation-link a {
  color: white;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}

.correlation-link a:hover {
  color: #013747;
  font-weight: bold;
}


@media (max-width: 992px) {
  .correlation-block {
    width: 100%;
    max-width: 95%;
    padding-left: 10px;
  }

  .correlations-row {
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
  }

  .research-title {
    font-size: 32px;
    color: #013747;
    letter-spacing: -0.63px;
    line-height: 33px;
  }

  .container {
    padding: 5px;
  }

  .research-header-detail {
    width: 83%;
    margin-bottom: 10px;
    padding: 30px;
  }

  .sticky-bar {
    display: none;
    justify-content: flex-start;
  }

  .research-img {

    width: 100%;
    height: 300px;
    margin-top: 50px;
    position: relative;
    right: 0;
    top: 0;
  }

  .di-cosa {
    padding: 15px 20px;
  }

  .research-details {
    width: 100%;
    margin-top: 20px;
  }

  .correlations h2, .correlations-section h3 {
    font-size: 40px;
  }

  .correlations a, .correlation-link {
    margin-bottom: 10px;
  }

  .correlations h2 {
    margin-left: 5px;
  }

  .richtext {
    overflow-x: auto;
  }

  .richtext table {
    width: 100%;
    table-layout: fixed;
  }

  .richtext table, .richtext th, .richtext td {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .back {
    display: none;
  }

  .section {
    padding-left: 20px;
    padding-right: 10px;
  }
}


</style>

