<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="logos-container">
        <div class="logos">
          <a href="/" class="logo-link"><img style="border-right: 1px dashed darkgray;padding-right: 8px"
                                             src="@/assets/logo-atlante-white.svg" alt="Company Logo" class="logo"/></a>
          <a href="https://www.unito.it/" target="_blank" class="logo-link"><img src="@/assets/logo-unito.png"
                                                                                 alt="Company Logo" class="logo "/></a>
          <a href="https://www.dcps.unito.it/do/home.pl/View?doc=osservatorio_professioni.html" target="_blank"
             class="logo-link"><img src="@/assets/logo-cps.png" alt="Company Logo" class="logo"/></a>
          <a href="https://www.dcps.unito.it/do/home.pl" target="_blank" class="logo-link"><img
              src="@/assets/logo-osservatorio.png" alt="Company Logo" class="logo"/></a>
        </div>
        <p class="footer-description">L'Atlante ti aiuta a navigare tra le professioni, i luoghi di lavoro, i titoli di
          studio e le competenze attraverso video, schede e statistiche per orientarti nel mercato del lavoro.</p>
      </div>

      <div class="bottom-content">
        <p class="copyright">&copy; 2012-2024 Università degli Studi di Torino, progetto Atlante delle Professioni</p>
        <div class="footer-links">
          <a href="/contatti" class="link">CONTATTI</a> / <a href="https://www.unito.it/privacy" target="_blank"
                                                             class="link">PRIVACY POLICY</a>
        </div>
        <div class="social-media">
          <a href="https://www.facebook.com/AtlantedelleProfessioni" target="_blank" class="social-link">
            <img src="@/assets/fb_icon.svg" alt="Facebook"/>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(1, 55, 71, 1);
  color: #fff;
  width: 100%;
  padding: 1rem;
  padding-top: 35px;
  text-align: center;
  z-index: 10;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin-bottom: 50px;
}

.logos-container {
  display: flex;
  width: 95%;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  margin-top: 60px;
}

.footer-description {
  width: 40%;
  color: #DEDDD6;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.3px;

}

.logos {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.logo-link .logo {
  height: 70px;
  margin-bottom: 1rem;
}
.footer-links .link {
  color: #fff;
  margin: 0 5px;
  text-decoration: none;
  font-weight: normal;
}

.social-media .social-link img {
  height: 40px; /* Adjusted for consistency */
}

.bottom-content {
  display: flex;
  flex-direction: row;
  gap: 120px;
  align-items: center;
  margin-top: 90px;
  width: 95%;

}

.bottom-content p {
  color: #DEDDD6;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.3px;
}

.footer-links a {
  font-family: "Montserrat", sans-serif;
  color: hsl(0deg, 0%, 100%);
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: normal;
}

@media (max-width: 992px) {
  .footer {
    padding: 0px 0px 0px 0px;
  }

  .footer-description {
    width: 80%;
  }

  .bottom-content {
    flex-direction: column;
    gap: 15px;
  }

  .bottom-content{
    align-items: flex-start;
  }
  .social-media {
    margin-left: 10px;
  }


}

/* Responsive adjustments */
@media (max-width: 768px) {

  .logo-link .logo {
    height: 60px;
  }

  .social-media {
    margin-top: 1rem;
  }
}

@media (max-width: 600px) {
  .logo-link .logo {
    height: 40px;
  }
}

</style>
