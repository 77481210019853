<template>
  <div class="search-bar-container">
    <div class="search-bar">
      <input
          type="text"
          placeholder="Cerca..."
          class="search-input"
          @click="openPopup"
      />

      <button class="search-bar-button" @click="openPopup">CERCA</button>

    </div>
  </div>
  <SearchPopupComponent
      :isVisible="isPopupOpen"
      @close-popup="closePopup"
  />
</template>

<script>
import {fetchProfessions} from "@/services/apiService";
import SearchPopupComponent from '@/components/General/SearchPopupComponent.vue';

export default {
  name: 'SearchBarComponent',
  components: {
    SearchPopupComponent
  },
  data() {
    return {
      isPopupOpen: false,
    };
  },
  methods: {
    openPopup() {
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
    }
  },
  // Optionally, fetch professions list on component mount
  async mounted() {
    this.professions = await fetchProfessions();
    console.log(this.professions);
  },

};
</script>

<style scoped>
.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: center; /* Center align the search bar */
  align-items: center;
  transition: all 0.3s ease;
  z-index: 20;
}

.search-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly; /* Space between items */
  width: 75%;
  max-width: 1000px;
  height: 85px;
  background-color: white;
  box-shadow: 0 22px 48px 0 rgba(0, 0, 0, 0.21);
  transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
}


.search-input {
  width: 90%;
  height: 100%;
  border: none;
  padding: 0 30px;
  background-color: white;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;

  color: #333333;
  flex-grow: 2;

  &:focus {
    outline: none;
  }
}

.search-bar:hover {
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.search-bar-button {
  height: 70%;
  width: 180px;
  margin-right: 10px;
  font-weight: bold;
  font-family: "Montserrat";
  color: white;
  background-color: #013747;
  border: none;
  outline: #013747;
  cursor: pointer;

}


/* Medium devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
  .search-bar {
    max-width: 600px;
  }

}

/* small devices (portrait phones, less than 600px) */
@media (max-width: 600px) {
  .search-bar {
    max-width: 400px;
  }

  .search-bar {
    width: 90%;
  }
}


</style>
