<template>
  <Header/>
  <div class="container">

    <section class="content">
      <div class="title-box">
        <h1>{{ pageTitle }}</h1>
        <p>{{ pageText }}</p>
      </div>
      <SearchBarComponent @search="filterProfessions"/>
      <p class="scroll-show">scorri<img style="margin-left: 12px"
                                        src="@/assets/arrow_right.svg"
                                        class="arrow-icon"
                                        alt="Video Icon"/></p>
      <BookShelfComponent :books="alphabetBooks" @bookSelected="filterProfessionsByLetter"/>

      <div class="legend-container">
        <IconsLegendComponent/>
      </div>

      <LoadingComponent :is-visible="isLoading"></LoadingComponent>
      <div class="results">
        <transition-group name="list" tag="div">
          <div v-for="(profession, title) in professionResults" :key="title" class="result-item">

            <router-link
                :to="{ name: 'ProfessionDetail', params: { identifier: profession.attributes.identifier || 'avvocato-avvocata' }}"
                class="result-item-text">
              <a>{{ title }}</a>
              <div v-if="profession.attributes.mini_description" v-html="profession.attributes.mini_description"
                   class="mini-description"></div>
            </router-link>
            <div style="display: flex; flex-direction: row; gap: 20px">
              <InfoIcon v-if="profession.attributes.related_videos.data.length" type="related_videos"></InfoIcon>
              <InfoIcon v-if="profession.attributes.foglia_bianca" type="foglia_bianca"></InfoIcon>
              <InfoIcon v-if="profession.attributes.foglia_verde" type="foglia_verde"></InfoIcon>
            </div>

          </div>
        </transition-group>

      </div>
    </section>
    <AppBottomComponent/>
    <Footer/>
  </div>
</template>

<script>
import BookShelfComponent from '@/components/ProfessionsBrowsePage/BookShelfComponent.vue';
import SearchBarComponent from '@/components/General/SearchBarComponent.vue';
import IconsLegendComponent from '@/components/General/IconsLegendComponent.vue';
import InfoIcon from '@/components/General/InfoIcon.vue';
import LoadingComponent from '@/components/General/LoadingScreenComponent.vue';
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import {fetchProfessions, retrievePage} from '@/services/apiService';
import AppBottomComponent from "@/components/General/AppBottomComponent.vue";

export default {
  name: 'ProfessionsBrowsePage',
  components: {
    Header,
    Footer,
    BookShelfComponent,
    SearchBarComponent,
    LoadingComponent,
    InfoIcon,
    AppBottomComponent,
    IconsLegendComponent
  },
  data() {
    return {
      alphabetBooks: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
      pageTitle: 'Professioni',
      pageText: 'Per ricercare la figura professionale che ti interessa, clicca sulle lettere per visualizzare l\'elenco alfabetico.',
      professionResults: {},
      allProfessions: {},
      isLoading: true,
      pageMetaTitle: 'Professioni | Atlante Delle Professioni',
      pageMetaDescription: '',
    };
  },
  async mounted() {
    await this.loadPage();
    await this.loadProfessions();
    this.setMetaTags();
  },
  beforeUnmount() {
    this.removeMetaTags();
  },
  computed: {
    pageURL() {
      return window.location.origin + window.location.pathname;
    }
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('Professions');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    async renderPage(pageData) {
      this.pageTitle = pageData.attributes.main_title;
      this.pageText = pageData.attributes.main_subtitle;
      this.pageMetaTitle = pageData.attributes.meta_title;
      this.pageMetaDescription = pageData.attributes.meta_description;
    },
    async loadProfessions() {
      try {
        const professions = await fetchProfessions();
        this.processProfessions(professions);
      } catch (error) {
        console.error('Error loading professions:', error);
      }
    },
    processProfessions(professions) {
      // populate denominations and specializations
      const professionsWithDenominations = this.populateDenominationsAndSpecializations(professions);

      this.allProfessions = professionsWithDenominations.reduce((accumulator, current) => {
        const title = current.attributes.identity.title;
        const startingLetter = title.charAt(0).toUpperCase();
        if (!accumulator[startingLetter]) {
          accumulator[startingLetter] = {};
        }
        // Store the entire profession object instead of just the id
        accumulator[startingLetter][title] = current;
        return accumulator;
      }, {});

      // Sort professions within each letter
      Object.keys(this.allProfessions).forEach(letter => {
        const sortedProfessions = Object.keys(this.allProfessions[letter])
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) // Sort titles case-insensitively
            .reduce((acc, title) => {
              // Rebuild the professions object in sorted order
              acc[title] = this.allProfessions[letter][title];
              return acc;
            }, {});

        this.allProfessions[letter] = sortedProfessions;
      });


      this.filterProfessionsByLetter('A');
      this.isLoading = false;
    },


    populateDenominationsAndSpecializations(professions) {
      const newProfessions = [];

      professions.forEach(profession => {
        // Check if the denominazione array exists and is not empty
        if (profession.attributes.denominazione && profession.attributes.denominazione.length > 0) {
          profession.attributes.denominazione.forEach(denomination => {
            if (denomination.title) {
              let description = `<p style="margin-top: 15px">Altra denominazione di <span style="font-weight:bold;">${profession.attributes.identity.title}</span></p>`;
              if (denomination.specializzazione) {
                description = `<p style="margin-top: 15px">Specializzazione di <span style="font-weight:bold;">${profession.attributes.identity.title}</span></p>`;
              }

              // Create a new profession object for each denomination
              const newProfession = {

                attributes: {
                  identifier: profession.attributes.identifier,
                  identity: {
                    title: denomination.title,
                  },
                  mini_description: description,
                  related_videos: profession.attributes.related_videos,
                  foglia_bianca: profession.attributes.foglia_bianca,
                  foglia_verde: profession.attributes.foglia_verde,
                }
              };
              newProfessions.push(newProfession);
            }
          });
        }
      });

      // Concatenate the new professions to the original professions array
      Array.prototype.push.apply(professions, newProfessions);
      return professions;
    },

    filterProfessionsByLetter(letter) {
      this.professionResults = this.allProfessions[letter] || {};
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    }
  },
};
</script>


<style>
/* Layout of the main container */
.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1px;
}

/* Content area styling */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
  padding-bottom: 40px;
}

/* Title box configuration */
.title-box {
  width: 75%;
  max-width: 1000px;
  align-items: flex-start;
  margin-bottom: 30px;
}

/* Title styling */
.title-box h1 {
  font-size: 110px;
  line-height: 87px;
  letter-spacing: -2px;
  font-weight: normal;
  color: #013747;
  margin-bottom: 0.5em;
  transition: font-size 0.3s ease;
  position: relative;
}

.title-box h1::after {
  content: '';
  display: block;
  position: absolute;
  top: -25px;
  left: 30%;
  width: 60%;
  height: 0.02em;
  background-image: linear-gradient(rgba(50, 205, 50, 0), limegreen 85%, rgba(50, 205, 50, 0));
  transform: translateX(-50%);
}


/* Subtitle styling */
.title-box p {
  font-size: 1.1em;
  color: #666;
  margin-top: 0;
  width: 80%;
}


/* Result list styling */
.results {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 75%;
  max-width: 1050px;
}

.mini-description p {
  font-size: 12px;
  line-height: 14px;
  font-family: "Montserrat";

}

/* Individual result item styling */
.result-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid snow;
  padding-top: 10px;
  padding-bottom: 40px;
  margin: 25px;
  color: #013747;
  transition: all 0.2s ease-in;
}

/* Styling for links in result items */
.result-item a {
  color: inherit;
  font-size: 30px;
  line-height: 32px;
  text-decoration: none;
  display: block;
  letter-spacing: -0.4px;
  font-family: "IBMPlexSans";
  margin-right: 40px;
}

.result-item-text {
  transition: all 0.2s ease-in;
  color: #013747;
}

.result-item-text:hover {
  color: white;
  transform: translateX(10px);
}


/* Secondary text in result items */
.result-item .secondary {
  color: #666;
  font-size: 1em;
  margin-top: 5px;
}

/* Transition styles for entering items */
.list-enter-active {
  transition: opacity 0.8s ease;
}

.list-enter, .list-enter-from {
  opacity: 0;
}

.list-enter-to {
  opacity: 1;
}

/* Styles for leaving items - make exit instant */
.list-leave-active, .list-leave-to {
  opacity: 0;
  transition: opacity 0s;
}

.legend-container {
  display: none;
}

.scroll-show {
  display: none;
}

/* Responsive design adjustments */
@media (max-width: 992px) {
  .title-box h1 {
    font-size: 55px;
    line-height: 44px;
    letter-spacing: -1.5px;
    margin-bottom: 40px;
    padding-top: 28px;
    width: 85%;
  }

  .results {
    width: 90%;
  }

  .result-item a {
    font-size: 20px;
    color: #013747;
    letter-spacing: -0.4px;
    line-height: 20px;
    font-family: "IBMPlexSans";
    margin-right: 15px;
  }

  .legend-container {
    display: block;
  }

  .scroll-show {
    display: block;
    float: right;
    width: 100%;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    margin-top: 45px;
    margin-right: 40px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 11px;
    color: #013747;
    letter-spacing: 1.29px;
    opacity: 1;
  }


}

@media (max-width: 600px) {
  .title-box p {
    font-size: 1em;
    width: 100%;
  }

  .results {
    width: 90%;
  }

  .title-box {
    width: 90%;
  }
}

</style>

