<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
#app {
  background-color: #e1e2e2;
}


body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling at the body level */
}


@font-face {
  font-family: 'IBMPlexSans';
  font-style: normal;
  src: local('Custom Font'),
  url('@/assets/fonts/IBMPlexSans-SemiBold.61f54ef0.woff2') format('woff2')
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: local('Custom Font'),
  url('@/assets/fonts/Montserrat-Regular.93dee51e.woff2') format('woff2')
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: local('Custom Font'),
  url('@/assets/fonts/Montserrat-SemiBold.803c1a37.woff2') format('woff2')
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBMPlexSans';
}

h1 {
  font-size: 35px;
}

p, tr, td, li, a {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 25px;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #333333;
}


/* Global styles (if any) */
</style>
