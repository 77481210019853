<template>
  <div class="search-button-container">

    <button class="search-p-button" @click="openPopup"><img src="@/assets/icon_search.svg" alt="Search"/></button>

  </div>
  <SearchPopupComponent
      :isVisible="isPopupOpen"
      @close-popup="closePopup"
  />
</template>

<script>
import {fetchProfessions} from "@/services/apiService";
import SearchPopupComponent from '@/components/General/SearchPopupComponent.vue';

export default {
  name: 'SearchButtonComponent',
  components: {
    SearchPopupComponent
  },
  data() {
    return {
      isPopupOpen: false,
    };
  },
  methods: {
    openPopup() {
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
    }
  },
  // Optionally, fetch professions list on component mount
  async mounted() {
    this.professions = await fetchProfessions();
  },

};
</script>

<style scoped>

.search-button-container {
  display: flex;
  width: 100%;
  height: 45px;
  background-color: transparent;
  justify-content: center;
  padding: 0;
  flex: inherit;
  cursor: pointer;
}

.search-p-button {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}




</style>
