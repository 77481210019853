<template>
  <div class="book" @mouseover="hover" @mouseleave="unhover" @click="selectBook">
    <!-- Use the public path to the image or the imported image variable -->
    <img src="@/assets/icons8-square-50.png" alt="Book icon" class="book-icon"/>
    <span class="book-letter">{{ letter }}</span>
  </div>
</template>

<script>
export default {
  name: 'BookComponent',
  props: {
    letter: String,
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    hover() {
      this.$el.classList.add('book-hover');
    },
    unhover() {
      if (!this.isSelected) {
        this.$el.classList.remove('book-hover');
      }
    },
    selectBook() {
      this.$emit('bookSelected', this.letter);
      this.isSelected = true;

      // Remove 'book-selected' class from all books
      const books = document.querySelectorAll('.book');
      books.forEach(book => {
        book.classList.remove('book-selected');
      });

      // Add 'book-selected' class to the clicked book
      this.$el.classList.add('book-selected');
    },
  },
};
</script>

<style scoped>
.book {
  transition: transform 0.3s ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 2em; /* Adjust size as needed */
  width: 32px; /* Width of the book */
  height: 32px; /* Height of the book */
  position: relative; /* Needed for absolute positioning of children */
}

.book-icon {
  position: absolute; /* Absolute position to allow the letter on top */
  opacity: 0.001; /* Completely opaque */
  width: 32px; /* Width of the book */
  height: 32px; /* Height of the book */
}

.book-letter {
  position: absolute;
  top: 50%; /* Center the letter vertically */
  left: 50%; /* Center the letter horizontally */
  transform: translate(-50%, -50%); /* Adjust positioning to the exact center */
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  color: #013747; /* Letter color */
  pointer-events: none; /* Prevents the letter from interfering with click events */
}

.book-selected {
  font-weight: bold;
}

</style>
