<template>

  <Header :showSearchButton="true"/>
  <div class="general-container">
    <div class="title-box">
      <div style="max-width: 1000px">
        <h1>{{ pageTitle }}</h1>
      </div>
    </div>

    <div class="related-professions" v-if="this.area">
      <ul>
        <li class="profession-title" v-for="profession in this.area.attributes.related_professions.data"
            :key="profession.id">
          <div style="width: 80%">
            <a style="display: flex; flex-direction: row; justify-content: space-between"
               :href="`/professioni/${profession.attributes.identifier}`">
              {{ profession.attributes.title }}
              <img style="margin-left: 12px" src="@/assets/arrow_right.svg" class="" alt="Video Icon"/>
            </a>
          </div>
          <InfoIcon v-if="profession.attributes.foglia_bianca" type="foglia_bianca"></InfoIcon>
          <InfoIcon v-if="profession.attributes.foglia_verde" type="foglia_verde"></InfoIcon>
        </li>
      </ul>
    </div>

  </div>
  <Footer/>
</template>

<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import InfoIcon from "@/components/General/InfoIcon.vue";
import {fetchSingleArea} from "@/services/apiService";

export default {
  name: "ProjectDetailPage",
  components: {
    Header,
    Footer,
    InfoIcon
  },
  data() {
    return {
      area: null, // Initialize project data
      pageTitle: '',
    };
  },
  async mounted() {

    try {
      const data = await this.loadAreaData(this.$route.params.id);
      this.area = data;
      this.pageTitle = this.area.attributes.title;
      console.log(this.area);
    } catch (error) {
      console.error('Error loading project data:', error);
      this.error = error;
    }
  },


  methods: {
    async loadAreaData(id) {
      try {
        return await fetchSingleArea(id);
      } catch (error) {
        console.error('Error fetching project data:', error);
        throw error;
      }
    },

  }
}
</script>

<style scoped>

.title-box h1 {

  font-size: 50px;
  line-height: 50px;
}

.profession-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 80px;
  font-weight: bold;
  font-size: 20px;
  transition: all 0.1s ease;
  color: #013747;
  border-bottom: 1px dotted white;
  margin-bottom: 5px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.profession-title:last-child {
  border-bottom: none;
  margin-bottom: 25px;
}

.profession-title a {
  text-decoration: none;
  color: inherit;
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 24px;
  font-family: "IBMPlexSans";
}

.general-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.profession-title:hover {
  color: white;
  transform: translateX(5px);
}

.related-professions {
  width: 65%;
}

@media (max-width: 1600px) {
  .related-professions {
    width: 75%;
  }

}
</style>
