<template>
  <nav class="nav-menu" :class="{ 'open': isOpen }">
    <button class="close-btn" @click="$emit('close-nav')">
      <img src="@/assets/close-btn-icon.png" alt="Close"/>
    </button>
    <div class="srch-comp"><img src="@/assets/icon_search_green.svg" alt="Close"/> <input
        type="text"
        placeholder="cerca"
        class="search-input-nav"
        @click="openPopup"
    /></div>

    <ul>
      <li v-for="item in navbarElements" :key="item.title">
        <a :href="item.link" :target="isExternalLink(item.link) ? '_blank' : '_self'">{{ item.title }}</a>
      </li>
    </ul>
  </nav>
  <SearchPopupComponent
      :isVisible="isPopupOpen"
      @close-popup="closePopup"
  />
</template>


<script>
import {retrievePage} from "@/services/apiService"; // Adjust the import path according to your project structure
import SearchPopupComponent from '@/components/General/SearchPopupComponent.vue';

export default {
  name: 'AppNavigationMenu',
  components: {SearchPopupComponent},
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      navbarElements: [],
      isPopupOpen: false,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('NavigationBar');
        this.navbarElements = pageData.attributes.navbar_element.map(item => ({
          title: item.title,
          link: item.link
        }));
      } catch (error) {
        console.error('Error loading page:', error);
        // Optionally update your component's state to reflect the error
      }
    },
    isExternalLink(link) {
      return link.startsWith('http://') || link.startsWith('https://');
    },
    openPopup() {
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
    }
  }
};
</script>


<style scoped>
.nav-menu {
  padding: 20px;
  display: flex; /* Set display to flex */
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-start; /* Center children horizontally */
  align-items: flex-start; /* Center children vertically */
  position: fixed;
  gap: 30px;
  top: 0;
  right: -100%;
  width: 400px;
  height: 100%;
  background: #042631;
  transition: right 0.3s;
  z-index: 1000;
}

.nav-menu.open {
  right: 0;
}

.srch-comp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 90px;
  margin-bottom: 40px;
}

.search-input-nav {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: white;

  &:focus {
    outline: none;
  }
}

.search-input-nav::placeholder {
  color: white;
}


.close-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}


.close-btn img {
  filter: brightness(0) invert(1);
  height: 35px;
  width: auto;
}

ul {
  list-style-type: none;
  padding: 0;
  text-align: left; /* Optional: align text in the center of each link */
  width: 100%;
}

li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  font-family: "IBMPlexSans";
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: -0.4px;
  line-height: 20px;
}


@media (max-width: 992px) {
  .nav-menu {
    right: -100%;
    width: 260px;

  }

  .nav-menu.open {
    right: 0;
  }
}

</style>
