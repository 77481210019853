<template>
  <div class="legenda">
    <div class="info-line"><img src="@/assets/icon_leaf_empty.svg" alt="professione rinverdita">
      <p><em>Professione rinverdita</em> perché la formazione include insegnamenti sulla sostenibilità ambientale</p>
    </div>
    <div class="info-line"><img src="@/assets/icon_leaf_green.svg" alt="professione verde">
      <p><em>Professione verde</em> perché ha come obiettivo principale la sostenibilità ambientale</p></div>
    <div class="info-line"><img src="@/assets/icon_screen.svg" alt="video approfondimento">
      <p>La professione è arricchita da video di approfondimento</p></div>
  </div>
</template>

<script>
export default {
  name: "IconsLegendComponent"
}
</script>

<style scoped>

.legenda {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: whitesmoke;
  padding: 20px;
  margin: 30px;
}

.legenda p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.75em;
}

.info-line {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

</style>