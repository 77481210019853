<template>
  <div class="container">
    <Header :showSearchButton="true"/>
    <section class="content">
      <div class="title-box">
        <div style="max-width: 600px">
          <h1>{{ pageTitle }}</h1>
          <div v-html="pageDescription" class="page-description"></div>
        </div>
      </div>
      <!-- Display Projects and Research -->
      <div v-if="error" class="error-message">{{ error }}</div>
      <div class="projects-and-research">
        <div class="projects">
          <h1>Progetti</h1>
          <LoadingComponent :is-visible="isLoadingProjects"></LoadingComponent>
          <div class="pr-item" v-for="project in projects" :key="project.id">
            <router-link :to="{ name: 'ProjectDetail', params: { identifier: project.attributes.identifier }}"><a>{{
                project.attributes.title
              }}</a>
            </router-link>
            <img src="@/assets/arrow_right.svg" alt="Info"/>
          </div>
        </div>
        <div class="research">
          <h1>Ricerche</h1>
          <LoadingComponent :is-visible="isLoadingResearch"></LoadingComponent>
          <div class="pr-item" v-for="researchItem in research" :key="researchItem.id">
            <router-link :to="{ name: 'researchDetail', params: { identifier: researchItem.attributes.identifier }}"><a>
              {{ researchItem.attributes.title }}</a>
            </router-link>
            <img src="@/assets/arrow_right.svg" alt="Info"/>
          </div>
        </div>
      </div>
    </section>
    <AppBottomComponent/>
    <Footer/>
  </div>
</template>


<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import {retrievePage, fetchProjects, fetchResearch} from "@/services/apiService";
import LoadingComponent from "@/components/General/LoadingScreenComponent.vue";
import AppBottomComponent from '@/components/General/AppBottomComponent.vue';

export default {
  name: "ProjectsResearchPage",
  components: {
    Header,
    Footer,
    LoadingComponent,
    AppBottomComponent
  },
  data() {
    return {
      pageTitle: 'Progetti e Ricerche',
      pageDescription: '',
      projects: [],
      research: [],
      error: null,
      isLoadingProjects: true,
      isLoadingResearch: true,
      pageMetaTitle: '',
      pageMetaDescription: '',
    };
  },
  async mounted() {
    await this.loadPage();
    this.setMetaTags();
    this.loadProjectsAndResearch();
  },
  beforeUnmount() {
    this.removeMetaTags();
  },
  computed: {
    pageURL() {
      return window.location.origin + window.location.pathname;
    }
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('ProjectsResearch');
        await this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    async renderPage(pageData) {
      this.pageTitle = pageData.attributes.main_title;
      this.pageDescription = pageData.attributes.description; // Assuming this is safe to render as HTML
      this.pageMetaTitle = pageData.attributes.meta_title;
      this.pageMetaDescription = pageData.attributes.meta_description;
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    },
    async loadProjectsAndResearch() {
      try {
        const projectsData = await fetchProjects();
        this.projects = projectsData;
        this.isLoadingProjects = false;

        const researchData = await fetchResearch();
        this.research = researchData;
        this.isLoadingResearch = false;
      } catch (error) {
        console.error('Error loading projects and research:', error);
      }
    },
  }
}
</script>


<style scoped>

.content {
  width: 93%;
}

.projects-and-research {
  width: 56%;
}


.projects h1, .research h1 {
  color: white;
  font-weight: bold;
  font-size: 55px;
}

.projects a, .research a {
  text-decoration: none;
  font-weight: bold;
}

.pr-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
  color: #013747;
  transition: color 0.2s ease, transform 0.2s ease;
}

.pr-item a {
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 20px;
  color: inherit;
}

.pr-item:hover {
  color: whitesmoke;
  transform: translateX(10px);
}

.title-box {
  max-width: 1300px;
}

.title-box h1 {
  font-size: 110px;
  line-height: 87px;
  letter-spacing: -2px;
}

@media (max-width: 1700px) {
  .projects-and-research {
    width: 58%;
  }
}

@media (max-width: 992px) {
  .title-box h1 {
    font-size: 55px;
    line-height: 44px;
    letter-spacing: -1.5px;
  }

  .title-box {
    margin-top: 30px;
  }

  .projects-and-research {
    width: 75%;
  }

  .projects-and-research h1 {
    font-size: 32px;
    color: hsl(0deg, 0%, 100%);
    letter-spacing: -0.63px;
    line-height: 33px;
  }

  .projects-and-research a {
    font-size: 20px;
    color: #013747;
    letter-spacing: -0.4px;
    line-height: 20px;
  }

  .title-box {
    width: 80%;
  }
}


</style>
