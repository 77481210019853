<template>
  <div class="home-container">
    <Header/>
    <img src="@/assets/biga.svg" alt="Overlay" class="home-overlay-image"/>
    <section class="home-content">
      <div class="title-box">
        <h1 class="page-title">{{ pageTitle }}</h1>
        <p>{{ pageDescription }}</p>
      </div>
      <SearchBarComponent/>

      <div class='home-links'>
        <div class="link-wrapper">
          <img src="@/assets/info-icon.svg" alt="Info"/>
          <a href="/che-cos-e">
            CHE COS'E L'ATLANTE DELLE PROFESSIONI
          </a>
        </div>
        <div class="link-wrapper">
          <img src="@/assets/statistics-icon.svg" alt="Statistics"/>
          <a href="https://statistiche.atlantedelleprofessioni.it" target="_blank">
            STATISTICHE SULL'OCCUPAZIONE DEI LAUREATI
          </a>
        </div>
        <div class="link-wrapper">
          <img src="@/assets/icon_projects.svg" alt="Info"/>
          <a href="/progetti-e-ricerche">
            PROGETTI E RICERCHE
          </a>
        </div>
      </div>

    </section>
    <SearchByTopicComponent/>
    <ProfessionSliderComponent/>
    <AppBottomComponent/>
    <Footer/>
  </div>
</template>


<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import AppBottomComponent from '@/components/General/AppBottomComponent.vue';
import SearchBarComponent from '@/components/General/SearchBarComponent.vue';
import SearchByTopicComponent from '@/components/HomePage/SearchByTopicComponent.vue';
import ProfessionSliderComponent from '@/components/HomePage/ProfessionSliderComponent.vue';
import {retrievePage} from "@/services/apiService";

export default {
  name: 'HomePage',
  components: {
    Header,
    Footer,
    AppBottomComponent,
    SearchBarComponent,
    SearchByTopicComponent,
    ProfessionSliderComponent,
  },
  data() {
    return {
      pageTitle: 'Cerca una professione',
      pageDescription: 'Sei uno studente, un laureato o un\'azienda? Grazie all\'Atlante delle Professioni puoi esplorare il mondo delle figure professionali formate dall\'Università degli Studi di Torino.',
      pageMetaTitle: '',
      pageMetaDescription: '',
    };
  },
  async mounted() {
    await this.loadPage();
    this.setMetaTags();
  },
  beforeUnmount() {
    this.removeMetaTags();
  },
  computed: {
    pageURL() {
      return window.location.origin + window.location.pathname;
    }
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('Home');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    async renderPage(pageData) {
      this.pageTitle = pageData.attributes.main_title;
      this.pageDescription = pageData.attributes.description;
      this.pageMetaTitle = pageData.attributes.meta_title;
      this.pageMetaDescription = pageData.attributes.meta_description;
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      // meta tags
      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    }
  }
};
</script>


<style>
/* Main container configuration */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
}

/* Central content area styling */
.home-content {
  align-items: center;
  margin-bottom: 10px;
  z-index: 10;
}

/* Title and subtitle box styling */
.title-box {
  text-align: left;
  margin: auto;
  max-width: 1250px;
}

/* Heading 1 and paragraph styling */
.title-box h1, .title-box p {
  color: #013747;
}

.title-box h1 {
  margin-bottom: 0.5em;
  line-height: 80%;
}

.title-box p {
  font-size: 1.2em;
  color: #666;
  margin-top: 0;
}

/* Links and icons styling */
.home-links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 50px;
  margin: 30px auto 110px;
  width: 60%;
  max-width: 1000px;
}

.link-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}


.link-wrapper a {
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  color: #013747;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  text-decoration-color: limegreen;
  text-underline-offset: 5px; /* Adjust the value as needed */
  align-items: center;
  transition: all 0.2s ease;
}


.home-links a:hover {
  color: whitesmoke;
}


.home-links img {
  height: 25px;
  margin-right: 8px;
}


.home-overlay-image {
  position: absolute;
  height: 1100px;
  top: 140px;
  right: -390px;
  z-index: 5;
}

@media (max-width: 1700px) {
  .home-overlay-image {
    right: -410px;
  }
}

@media (max-width: 992px) {
  .title-box h1 {
    font-size: 55px;
    line-height: 44px;
    letter-spacing: -1.5px;
    margin-bottom: 40px;
    padding-top: 28px;
    width: 85%;
  }

  .home-overlay-image {
    right: -790px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 10;
    width: 1000px;
    height: 1150px;
    top: -100px;
    left: calc(100vw - 210px);
    display: none;
  }

  .home-links {
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 60px;
    width: 85%;
    gap: 20px;
  }

  .home-links img {
    height: 22px;
    margin-right: 8px;
  }

  .link-wrapper a {
    font-size: 10px;
  }


  .home-links a::after {
    display: none;
  }
}


</style>
