<template>
  <div class="video" @click="openVideo">
    <div style="position: relative; margin: 0;padding: 0">
      <img :src="getVideoThumbnailUrl(videoObj) ? getVideoThumbnailUrl(videoObj) : defaultThumbnail"
           alt="Video Thumbnail"
           class="thumbnail"/>
      <img class="play-icon" src="@/assets/play-videos-icon.svg" alt="play"/>
    </div>
    <div class="video-info">
      <h3>{{ videoObj.attributes.title }}</h3>
      <p> {{ videoObj.attributes.type }}</p>
    </div>
  </div>
</template>

<script>
import defaultThumbnail from '@/assets/default-video-thumbnail.webp';

export default {
  name: "VideoComponent",
  components: {},
  props: {
    videoObj: Object,
  },
  data() {
    return {
      defaultThumbnail,
    };
  },
  methods: {
    openVideo() {
      // Emitting video ID instead of the whole video object
      this.$emit('open-popup', this.videoObj.id);
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },

    getVideoThumbnailUrl(video) {
      let thumbnailLink = '';
      if (video.attributes.thumbnail && video.attributes.thumbnail.data) {
        thumbnailLink = `${process.env.VUE_APP_BASE_BACK_URL}${video.attributes.thumbnail.data.attributes.url}`;
      }
      return thumbnailLink;
    },
  }
}
</script>

<style scoped>
.video {
  cursor: pointer;
  width: 31%;
  max-width: 550px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
}

.video:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.thumbnail {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.video-info {
  background-color: #fff; /* Light theme */
  padding: 5px 5px 20px 25px;
  text-align: left;
}

.video-info h3 {
  /* Adjust the clamp values as needed: min-size, value based on viewport, max-size */
  margin: 10px 0 5px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines you want the text to be clamped at */
  -webkit-box-orient: vertical;
  font-size: 20px;
  color: #013747;
  letter-spacing: -0.4px;
  line-height: 21px;
  width: 100%;
}


.video-info p {
  margin-top: 20%;
  font-size: 11px;
  color: #00CC00;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.play-icon {
  position: absolute;
  right: 4%;
  bottom: 5%;
  width: 25px;
  float: right;
}


@media (max-width: 992px) {


  .play-icon {
    display: none;
  }

  .video-info p {
    margin-top: 25px;
    font-size: 12px;
    color: limegreen;
  }

  .video {
    width: 98%;
  }
}
</style>
