// src/router.js

import {createRouter, createWebHistory} from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import ProfessionsBrowsePage from '@/views/ProfessionsBrowsePage.vue';
import AboutPage from '@/views/AboutPage.vue';
import AcademicCoursesPage from '@/views/AcademicCoursesPage.vue';
import ProfessionalAreasPage from '@/views/ProfessionalAreasPage.vue';
import VideosPage from '@/views/VideosPage.vue';
import JobPlacementPage from '@/views/JobPlacementPage.vue';
import ProfessionDetailPage from '@/views/ProfessionDetailPage.vue';
import ContattiPage from "@/views/ContattiPage.vue";
import LeafPage from "@/views/LeafPage.vue";
import ProjectsResearchPage from "@/views/ProjectsResearchPage.vue";
import projectDetailPage from "@/views/ProjectDetailPage.vue";
import researchDetailPage from "@/views/ResearchDetailPage.vue";
import BrowseByAreaPage from "@/views/BrowseByAreaPage.vue";
import NotFoundPage from '@/views/NotFoundPage.vue';
// import other components as needed

// Define routes
export const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: {
            title: 'Home page | Atlante Delle Professioni '
        }
    },
    {
        path: '/professioni',
        name: 'Professioni',
        component: ProfessionsBrowsePage,
        meta: {
            title: 'Professioni | Atlante Delle Professioni '
        }
    },
    {
        path: '/che-cos-e',
        name: 'Che cos\'è',
        component: AboutPage,
        meta: {
            title: 'Che cos\'è | Atlante Delle Professioni '
        }
    },
    {
        path: '/corsi-di-laurea',
        name: 'Corsi di laurea',
        component: AcademicCoursesPage,
        meta: {
            title: 'Corsi di laurea | Atlante Delle Professioni '
        }
    },
    {
        path: '/aree-professionali',
        name: 'Aree professionali',
        component: ProfessionalAreasPage,
        meta: {
            title: 'Aree professionali | Atlante Delle Professioni '
        }
    },
    {
        path: '/video',
        name: 'Video',
        component: VideosPage,
        meta: {
            title: 'Video | Atlante Delle Professioni '
        }
    },
    {
        path: '/job-placement',
        name: 'Job Placement',
        component: JobPlacementPage,
        meta: {
            title: 'Job Placement | Atlante Delle Professioni '
        }
    },
    {
        path: '/progetti-e-ricerche',
        name: 'Progetti e ricerche',
        component: ProjectsResearchPage,
        meta: {
            title: 'Progetti e ricerche | Atlante Delle Professioni '
        }
    },
    {
        path: '/professioni/:identifier',
        name: 'ProfessionDetail',
        component: ProfessionDetailPage
    },
    {
        path: '/progetti-e-ricerche/progetti/:identifier',
        name: 'ProjectDetail',
        component: projectDetailPage
    },
    {
        path: '/aree-professionali/:id',
        name: 'BrowseByArea',
        component: BrowseByAreaPage
    },
    {
        path: '/progetti-e-ricerche/ricerche/:identifier',
        name: 'researchDetail',
        component: researchDetailPage
    },
    {
        path: '/contatti',
        name: 'contatti',
        component: ContattiPage,
        meta: {
            title: 'Atlante Delle Professioni '
        }
    },
    {
        path: '/professioni-verdi-e-rinverdite',
        name: 'Professioni verdi e rinverdite',
        component: LeafPage,
        meta: {
            title: 'Atlante Delle Professioni '
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundPage,
        meta: {
            title: '404 Not Found | Atlante delle professioni'
        }
    },
    // Add other routes here
];

// Create router instance
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // This will change the title dynamically
    window.scrollTo(0, 0);
    document.title = to.meta.title || 'Atlante delle professioni';
    next();
});

export default router;
