// Common utilities to reduce duplication and improve readability

const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;
const ACCESS_TOKEN = process.env.VUE_APP_BACKEND_ACCESS_TOKEN;
const BASE_BACK_URL = process.env.VUE_APP_BASE_BACK_URL; // Adjusted for consistency

const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${ACCESS_TOKEN}`,
};

// Function to handle fetching data from the API
async function fetchDataFromAPI(url, options = {}) {
    const response = await fetch(url, {...options, headers});
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}

// API Service Functions

export async function fetchProfessions() {
    let allProfessions = [];
    let page = 1;
    let totalPages = null;

    try {
        while (totalPages === null || page <= totalPages) {
            const url = `${BASE_API_URL}/professions?populate=*&pagination[page]=${page}&pagination[pageSize]=100`;
            const data = await fetchDataFromAPI(url);

            allProfessions = [...allProfessions, ...data.data];

            if (totalPages === null && data.meta.pagination) {
                totalPages = data.meta.pagination.pageCount;
            }

            page++;
        }

        console.log("All professions fetched:", allProfessions.length);
        return allProfessions;
    } catch (error) {
        console.error('Error fetching all professions:', error);
        throw error;
    }
}


export async function fetchSingleProfession(identifier) {
    try {

        const idExtractorUrl = `${BASE_API_URL}/professions?filters[identifier][$eq]=${identifier}&populate=*`;
        const idExtractor = await fetchDataFromAPI(idExtractorUrl);

        const id = idExtractor.data[0].id;
        const professionDetailsUrl = `${BASE_API_URL}/professions/${id}?populate=*`;
        const profession = await fetchDataFromAPI(professionDetailsUrl);

        const identityImageUrl = `${BASE_API_URL}/professions/${id}?populate=identity.image`;
        const identityImageResponse = await fetchDataFromAPI(identityImageUrl);

        const compenteze = `${BASE_API_URL}/professions/${id}?populate=attivita_compenteze.formale_courses&populate=attivita_compenteze.consigliato_courses&populate=attivita_compenteze.comportamenti&populate=related_videos&populate=corsi_di_laurea.job_placement`;
        const compentezeResponse = await fetchDataFromAPI(compenteze);
        profession.data.attributes.attivita_compenteze = compentezeResponse.data.attributes.attivita_compenteze;
        profession.data.attributes.corsi_di_laurea = compentezeResponse.data.attributes.corsi_di_laurea;


        const imageUrl = `${BASE_BACK_URL}${identityImageResponse.data.attributes.identity.image.data.attributes.url}`;
        profession.data.attributes.image = imageUrl;


        return profession.data;
    } catch (error) {
        console.error('Error fetching single profession:', error);
        throw error;
    }
}

export async function fetchSingleProfessionById(id) {
    try {
        const professionDetailsUrl = `${BASE_API_URL}/professions/${id}?populate=*`;
        const profession = await fetchDataFromAPI(professionDetailsUrl);

        const identityImageUrl = `${BASE_API_URL}/professions/${id}?populate=identity.image`;
        const identityImageResponse = await fetchDataFromAPI(identityImageUrl);

        const compenteze = `${BASE_API_URL}/professions/${id}?populate=attivita_compenteze.formale_courses&populate=attivita_compenteze.consigliato_courses&populate=attivita_compenteze.comportamenti&populate=related_videos&populate=corsi_di_laurea.job_placement`;
        const compentezeResponse = await fetchDataFromAPI(compenteze);
        profession.data.attributes.attivita_compenteze = compentezeResponse.data.attributes.attivita_compenteze;
        profession.data.attributes.corsi_di_laurea = compentezeResponse.data.attributes.corsi_di_laurea;


        const imageUrl = `${BASE_BACK_URL}${identityImageResponse.data.attributes.identity.image.data.attributes.url}`;
        profession.data.attributes.image = imageUrl;


        return profession.data;
    } catch (error) {
        console.error('Error fetching single profession:', error);
        throw error;
    }
}

export async function fetchProfessionalAreas() {
    const url = `${BASE_API_URL}/professional-areas?populate=deep,3&pagination[pageSize]=100`;
    const professionAreas = await fetchDataFromAPI(url);
    return professionAreas.data;
}

export async function fetchSingleArea(id) {
    try {
        const professionAreasDetailsUrl = `${BASE_API_URL}/professional-areas/${id}?populate=*`;
        const professionAreas = await fetchDataFromAPI(professionAreasDetailsUrl);


        return professionAreas.data;
    } catch (error) {
        console.error('Error fetching single profession:', error);
        throw error;
    }
}

export async function fetchCourses() {
    let allCourses = [];
    let page = 1;
    let totalPages = null;

    try {
        while (totalPages === null || page <= totalPages) {
            const url = `${BASE_API_URL}/degree-courses?populate=deep,3&pagination[page]=${page}&pagination[pageSize]=100`;
            const data = await fetchDataFromAPI(url);

            allCourses = [...allCourses, ...data.data]; // Concatenate the newly fetched courses to the allCourses array

            // Check if totalPages is not set yet and set it based on the pagination info from the API response
            if (totalPages === null && data.meta && data.meta.pagination) {
                totalPages = data.meta.pagination.pageCount;
            }

            page++; // Increment page number to fetch the next page in the next iteration
        }

        console.log("All courses fetched:", allCourses.length);
        return allCourses;
    } catch (error) {
        console.error('Error fetching all courses:', error);
        throw error;
    }
}

export async function fetchProjects() {
    let allProjects = [];
    let page = 1;
    let totalPages = null;

    try {
        while (totalPages === null || page <= totalPages) {
            const url = `${BASE_API_URL}/progetti?populate=*&pagination[page]=${page}&pagination[pageSize]=100`;
            const data = await fetchDataFromAPI(url);

            allProjects = [...allProjects, ...data.data];

            if (totalPages === null && data.meta.pagination) {
                totalPages = data.meta.pagination.pageCount;
            }

            page++;
        }

        console.log("All projects fetched:", allProjects.length);
        return allProjects;
    } catch (error) {
        console.error('Error fetching all professions:', error);
        throw error;
    }
}

export async function fetchResearch() {
    let allResearch = [];
    let page = 1;
    let totalPages = null;

    try {
        while (totalPages === null || page <= totalPages) {
            const url = `${BASE_API_URL}/ricerce?populate=*&pagination[page]=${page}&pagination[pageSize]=100`;
            const data = await fetchDataFromAPI(url);

            allResearch = [...allResearch, ...data.data];

            if (totalPages === null && data.meta.pagination) {
                totalPages = data.meta.pagination.pageCount;
            }

            page++;
        }

        console.log("All Research fetched:", allResearch.length);
        return allResearch;
    } catch (error) {
        console.error('Error fetching all professions:', error);
        throw error;
    }
}

export async function fetchSingleProject(identifier) {
    try {

        const idExtractorUrl = `${BASE_API_URL}/progetti?filters[identifier][$eq]=${identifier}&populate=*`;
        const idExtractor = await fetchDataFromAPI(idExtractorUrl);

        const id = idExtractor.data[0].id;

        const projectDetailsUrl = `${BASE_API_URL}/progetti/${id}?populate=*`;
        const project = await fetchDataFromAPI(projectDetailsUrl);

        const projectDetailsUrlWithImage = `${BASE_API_URL}/progetti/${id}?populate=image`;
        const projectWithImage = await fetchDataFromAPI(projectDetailsUrlWithImage);

        try {
            project.data.attributes.image = `${BASE_BACK_URL}${projectWithImage.data.attributes.image.data.attributes.url}`
        } catch (error) {
            console.error(error)
        }

        return project.data;
    } catch (error) {
        console.error('Error fetching single project:', error);
        throw error;
    }
}

export async function fetchSingleMaterial(id) {
    try {
        const materialDetailsUrl = `${BASE_API_URL}/materials/${id}?populate=*`;
        const material = await fetchDataFromAPI(materialDetailsUrl);

        material.data.attributes.file.data.attributes.url = `${BASE_BACK_URL}${material.data.attributes.file.data.attributes.url}`

        return material.data;
    } catch (error) {
        console.error('Error fetching single material:', error);
        throw error;
    }
}

export async function fetchSingleResearch(identifier) {
    try {

        const idExtractorUrl = `${BASE_API_URL}/ricerce?filters[identifier][$eq]=${identifier}&populate=*`;
        const idExtractor = await fetchDataFromAPI(idExtractorUrl);

        const id = idExtractor.data[0].id;

        const researchDetailsUrl = `${BASE_API_URL}/ricerce/${id}?populate=*`;
        const research = await fetchDataFromAPI(researchDetailsUrl);

        const researchDetailsUrlWithImage = `${BASE_API_URL}/ricerce/${id}?populate=image`;
        const researchWithImage = await fetchDataFromAPI(researchDetailsUrlWithImage);

        try {
            research.data.attributes.image = `${BASE_BACK_URL}${researchWithImage.data.attributes.image.data.attributes.url}`
        } catch (error) {
            console.error(error)
        }

        return research.data;
    } catch (error) {
        console.error('Error fetching single research:', error);
        throw error;
    }
}

export async function fetchVideos() {
    let allVideos = [];
    let page = 1;
    let totalPages = null;

    try {
        while (totalPages === null || page <= totalPages) {
            const url = `${BASE_API_URL}/videos?populate=*&pagination[page]=${page}&pagination[pageSize]=100`;
            const data = await fetchDataFromAPI(url);

            allVideos = [...allVideos, ...data.data];

            if (totalPages === null && data.meta.pagination) {
                totalPages = data.meta.pagination.pageCount;
            }

            page++;
        }

        console.log("All videos fetched:", allVideos.length);
        return allVideos;
    } catch (error) {
        console.error('Error fetching all professions:', error);
        throw error;
    }
}

export async function fetchSingleVideo(id) {
    try {
        const videoDetailsUrl = `${BASE_API_URL}/videos/${id}?populate=*`;
        const video = await fetchDataFromAPI(videoDetailsUrl);

        const videoDetailsUrlExtra = `${BASE_API_URL}/videos/${id}?populate=related_profession.related_videos.thumbnail`;
        const videoExtra = await fetchDataFromAPI(videoDetailsUrlExtra);


        video.data.attributes.related_videos = videoExtra.data.attributes.related_profession.data.attributes.related_videos;


        return video.data;

    } catch (error) {
        console.error('Error fetching single profession:', error);
        throw error;
    }
}

export async function fetchJobPlacements() {
    const url = `${BASE_API_URL}/job-placements?populate=*&pagination[pageSize]=100`;
    const jobPlacements = await fetchDataFromAPI(url);
    return jobPlacements.data;
}

export async function retrievePage(key) {
    const pagesApiRoute = {
        'Home': 'page-1-homepage?populate=*',
        'Professions': 'page-3-professioni?populate=*',
        'JobPlacement': 'page-7-job-placement?populate=*',
        'About': 'page-2-che-cos-e?populate=*',
        'ProfessionalAreas': 'page-5-aree-professionali?populate=*',
        'DegreeCourses': 'page-4-corsi-di-laurea?populate=*',
        'ProjectsResearch': 'progettiricerche?populate=*',
        'NavigationBar': 'navigation-bar?populate=*',
        'Contatti': 'contatti',
        'LeafPage': 'pagina-foglie',
        'Video': 'page-6-video',

    };

    try {

        const backendUrl = `${BASE_API_URL}/${pagesApiRoute[key]}`;
        const page = await fetchDataFromAPI(backendUrl);
        if (key === "Home") {
            const backendUrl = `${BASE_API_URL}/page-1-homepage?populate=profession_previews.identity&populate=profession_previews.identity.image`;
            const extraInfo = await fetchDataFromAPI(backendUrl);
            page.data.attributes.profession_previews = extraInfo.data.attributes.profession_previews;
        }
        return page.data;
    } catch (error) {
        console.error('Error fetching page:', error);
        throw error;
    }
}
