<template>
  <div class="container">
    <Header :showSearchButton="true"/>
    <section class="content">
      <div class="title-box">
        <h1>{{ pageTitle }}</h1>
        <div v-html="pageDescription" class="page-description"></div>

        <div class="job-placement-links">
          <div v-for="jobPlacement in jobPlacements" :key="jobPlacement.id"
               style="display: flex;flex-direction: row;align-items: center;gap: 10px">
            <a style="margin-right: 10px" :href="jobPlacement.attributes.link"
               target="_blank">{{ jobPlacement.attributes.title }}</a>
            <a :href="jobPlacement.attributes.link" target="_blank"><img src="@/assets/arrow_right.svg"
                                                                         alt="Info"/></a>
          </div>

        </div>
      </div>

    </section>
    <Footer/>
  </div>
</template>


<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import {fetchJobPlacements, retrievePage} from "@/services/apiService";

export default {
  name: "JobPlacementPage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      pageTitle: 'Job Placement',
      pageDescription: '',
      jobPlacements: [],
      error: null,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('JobPlacement');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    async renderPage(pageData) {
      this.pageTitle = pageData.attributes.main_title;
      this.pageDescription = pageData.attributes.description;
      this.jobPlacements = await fetchJobPlacements();
    }
  }
}
</script>


<style scoped>

.content .title-box {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.job-placement-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 60px;
}

.job-placement-links a {
  text-decoration: none;
  color: #013747;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 24px;
  font-family: "IBMPlexSans";
  margin: 10px 0;
}

.title-box {
  margin-top: 40px;
  width: 75%;
  max-width: 1120px;
}

.title-box h1 {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 60px;
}

.page-description {
  color: #323232;
  font-size: 16px;
}
</style>
