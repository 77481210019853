<template>
  <header class="header">
    <div class="header-container">
      <a href="/"><img src="@/assets/logo-atlante.svg" alt="Atlante delle Professioni Logo"
                       class="logo logo-atlante"></a>
      <a href="https://www.unito.it/" target="_blank"><img src="@/assets/logo-unito.svg" alt="Università di Torino Logo"
                                                           class="logo logo-unito"></a>

      <div style="display: flex;flex-direction: row; gap: 30px">
        <!-- Conditionally render SearchButtonComponent based on currentPage -->
        <div v-if="showSearchButton" class="srch-btn-container">
          <SearchButtonComponent v-if="showSearchButton"/>
        </div>
        <button class="nav-toggle-btn" @click="toggleNav">
          <img src="@/assets/nav-icon.png" alt="Toggle Navigation"/>
        </button>
      </div>

    </div>
  </header>
  <AppNavigationMenu :isOpen="isNavOpen" @close-nav="toggleNav"/>
</template>


<script>
// Import the navigation menu component
import AppNavigationMenu from '@/components/General/AppNavigationMenu.vue';
import SearchButtonComponent from '@/components/General/SearchButtonComponent.vue';

export default {
  name: 'AppHeader',
  components: {
    AppNavigationMenu,
    SearchButtonComponent
  },
  data() {
    return {
      isNavOpen: false,
    };
  },
  props: {
    showSearchButton: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
  },
};
</script>


<style scoped>
.header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
//background-color: rgba(1, 55, 71,0.02);
}

.header-container {
  width: 98%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  padding: 2rem 0% 3rem 0%;
}

.logo {
  height: auto;
  width: auto; /* Ensure the logo maintains its aspect ratio */
  transition: height 0.5s ease;
}

/* Specific logo adjustments */
.logo-atlante {
  height: 110px; /* Default size for large screens */
  width: auto;
}

.logo-unito {
  height: 90px; /* Default size for large screens */
  width: auto;
}

.nav-toggle-btn {
  background: transparent;
  border: none;
  padding: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nav-toggle-btn img {
  height: 24px; /* Or the size of your preference */
  width: 24px; /* Maintain the aspect ratio */
}

.srch-btn-container {
  width: 200px;
  background-color: #3BFF3B;
}


@media (max-width: 1600px) {
  .header-container {
    width: 98%;
  }
}

@media (max-width: 1300px) {
  .header-container {
    width: 90%;
  }

  .srch-btn-container {
    width: 50px;
    margin-left: 5px;
  }
}

@media (max-width: 992px) {

  .srch-btn-container {
    width: 50px;
    margin-left: 0px;
  }

  .logo-atlante,
  .logo-unito {
    height: 50px;
  }

}

@media (max-width: 768px) {
  .header-container {
    padding: 0.5rem 10%;
    width: 95%;
  }

  .nav-toggle-btn {
    display: flex;
  }

}

/* small devices (portrait phones, less than 600px) */
@media (max-width: 600px) {
  .header-container {
    padding: 0.5rem 5%;
    width: 95%;
  }

}


</style>
